import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const RadioGroupLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  margin: 0 0 18px;

  @media ${breakpoint.md} {
    margin: 0 0 18px;
  }
`;

export const SelectGroupLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  margin: 0 8px 18px;

  @media ${breakpoint.md} {
    margin: 0 8px 18px;
  }
`;

export const FormGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media ${breakpoint.lg} {
    flex-wrap: nowrap;
  }
`;

export const SavingsType = styled.div`
  flex-shrink: 0;
  padding: 0 8px;
  margin-bottom: 40px;
  @media ${breakpoint.lg} {
    border: solid 1px rgba(0, 0, 0, 0.3);
    border-width: 0 0 0 1px;
    padding-left: 40px;
    padding-right: 20px;
    margin-bottom: 0;
  }
`;

export const SavingsTypeItem = styled.div`
  @media ${breakpoint.tabletOnly} {
    display: inline-block;
  }
  &:not(:last-child) {
    margin-bottom: 26px;
    @media ${breakpoint.tabletOnly} {
      margin-bottom: 0;
    }
  }
`;

export const SelectGroup = styled.div`
  width: 100%;
  @media ${breakpoint.tabletAndBeyond} {
    width: 50%;
  }
`;

export const SelectGroupGroup = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const RadioGroupGroup = styled.div`
  width: 100%;
  padding: 0 8px;
  margin-bottom: 30px;

  @media ${breakpoint.sm} {
    display: flex;
    flex-wrap: wrap;
  }

  @media ${breakpoint.lg} {
    width: 250px;
    padding-right: 20px;
    padding-left: 0;
    margin-bottom: 0;
  }
`;

export const RadioGroupGroupIreland = styled.div`
  width: 100%;
  margin-bottom: 30px;
  display: flex;

  @media ${breakpoint.lg} {
    flex-wrap: nowrap;
    width: 550px;
    padding-right: 20px;
    padding-left: 0;
    margin-bottom: 0;
    align-items: center;
  }
`;

export const RadioGroup = styled.div`
  width: 100%;
  @media ${breakpoint.tabletOnly} {
    width: 50%;
  }
  &:not(:last-child) {
    margin-bottom: 30px;
    @media ${breakpoint.sm} {
      margin-bottom: 0;
    }
  }
`;

export const RadioGroupIreland = styled.div`
  width: 50%;
  @media ${breakpoint.sm} {
    padding: 0 8px;
  }
  &:not(:last-child) {
    // margin-bottom: 30px;
    @media ${breakpoint.sm} {
      margin-bottom: 0;
    }
  }
`;

export const FuelTypeItems = styled.div`
  @media ${breakpoint.sm} {
    display: flex;
  }
`;

export const FuelTypeItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 26px;
    @media ${breakpoint.tabletAndBeyond} {
      margin-bottom: 0;
    }
  }
`;

export const SavingsPeriodItems = styled.div`
  @media ${breakpoint.sm} {
    display: flex;
  }
`;

export const SavingsPeriodItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 26px;
    @media ${breakpoint.tabletAndBeyond} {
      margin-bottom: 0;
    }
  }
`;
