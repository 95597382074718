import React, { useState, useCallback, useLayoutEffect } from 'react';
import { bool, func, shape } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Waypoint } from 'react-waypoint';

import { Button } from '@styles/globalStyles';
import { MapContainer } from '@components/Map/style';
import useMatchBreakpoint from '@root/hooks/useMatchBreakpoint';
import { breakpoint } from '@styles/breakpoints';

const Map = ({
  mapDataStore: { showMapOnMobile, initMap, getData, inProgress, resetForm },
}) => {
  const [initialized, setInitialized] = useState(false);
  const isTablet = useMatchBreakpoint(breakpoint.tabletPortrait);

  useLayoutEffect(() => {
    initMap();
  }, []);

  const getMapData = useCallback(() => {
    if (!initialized) {
      getData();
      setInitialized(true);
    }
  }, [initialized]);

  return (
    <>
      <Waypoint onEnter={getMapData} topOffset={1000}>
        <MapContainer
          show={showMapOnMobile || isTablet}
          loading={inProgress ? 1 : 0}
          id="map"
        />
      </Waypoint>
      {showMapOnMobile && (
        <Button variant="primary" onClick={resetForm}>
          Search again
        </Button>
      )}
    </>
  );
};

Map.propTypes = {
  mapDataStore: shape({
    showMapOnMobile: bool,
    inProgress: bool,
    initMap: func,
    getData: func,
    resetForm: func,
  }).isRequired,
};

export default inject('mapDataStore')(observer(Map));
