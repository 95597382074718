import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const Main = styled.main`
  margin-bottom: 40px;

  @media ${breakpoint.md} {
    margin-bottom: 80px;
  }
`;
