import calculatorStore from '@root/stores/calculatorStore';
import carsStore from '@root/stores/carsStore';
import retailerStore from '@root/stores/retailerStore';

const RETAILER_PATH = 'retailer';
const RETAILER_ROUTE = `/${RETAILER_PATH}/:dealerNumber`;
export const PAGES = {
  homepage: 'homepage',
  phev: 'phev',
  electric: 'electric',
  charging: 'charging',
  chargingFaqs: 'charging-faqs',
  calculationsExplained: 'calculations-explained',
  electricCalculationsExplained: 'electric-calculations-explained',
  cookiePolicy: 'cookie_policy',
};

const routes = {
  homepage: '/',
  phev: '/phev',
  electric: '/electric',
  charging: '/charging',
  chargingFaqs: '/charging-faqs',
  calculationsExplained: '/calculations-explained',
  electricCalculationsExplained: '/electric-calculations-explained',
  cookiePolicy: '/cookie_policy',
};

const retailerRoutes = {
  homepage: `${RETAILER_ROUTE}/`,
  phev: `${RETAILER_ROUTE}/phev`,
  electric: `${RETAILER_ROUTE}/electric`,
  charging: `${RETAILER_ROUTE}/charging`,
  chargingFaqs: `${RETAILER_ROUTE}/charging-faqs`,
  calculationsExplained: `${RETAILER_ROUTE}/calculations-explained`,
  electricCalculationsExplained: `${RETAILER_ROUTE}/electric-calculations-explained`,
  cookiePolicy: `${RETAILER_ROUTE}/cookie_policy`,
};

export default routes;

export { retailerRoutes, RETAILER_PATH, RETAILER_ROUTE };

const onPageChange = () => {
  carsStore.resetCars();
  calculatorStore.resetCalculator();
};

export const routeFor = (route) =>
  retailerStore.dealerNumber
    ? `/${RETAILER_PATH}/${retailerStore.dealerNumber}${routes[route]}`
    : routes[route];

export const NAVBAR_ITEMS = [
  {
    label: 'Home',
    route: () => routeFor('homepage'),
    callback: onPageChange,
  },
  {
    label: 'Electric benefits',
    route: () => routeFor('electric'),
    callback: onPageChange,
  },
  {
    label: 'Plug-in Hybrid benefits',
    route: () => routeFor('phev'),
    callback: onPageChange,
  },
  {
    label: 'Charging',
    route: () => routeFor('charging'),
    callback: onPageChange,
  },
];
