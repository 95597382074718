import styled from '@emotion/styled';

export const AccordionItemWrap = styled.div`
  border-bottom: solid 1px #bbbbbb;
`;

export const AccordionItemHead = styled.div`
  padding: 20px 10px;
  padding-left: 35px;
  font-family: BMW-Bold, sans-serif;
  font-size: 15px;
  position: relative;
  cursor: pointer;
  &:before {
    content: '';
    dipsplay: block;
    width: 10px;
    height: 10px;
    border: solid 1px #262626;
    border-width: 0 3px 3px 0;
    transform: translateY(-50%) rotate(45deg);
    position: absolute;
    top: calc(50% - 2px);
    left: 10px;
    transition: all 0.3s;
    [data-open='true'] & {
      transform: translateY(-50%) rotate(-135deg);
      top: calc(50% + 2px);
    }
  }
`;

export const AccordionItemBody = styled.div`
  overflow: hidden;
  transition: height 0.3s;
`;

export const AccordionItemBodyInner = styled.div`
  padding-bottom: 20px;
`;
