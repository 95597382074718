import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Line,
  Label,
  Title,
  Approximately,
} from '@containers/Home/components/JourneyTile/style';

import { InfoButton } from '@containers/Home/components/JourneyTile/components/style';
import InfoTooltip from '@components/Tooltip/components/InfoTooltip';
import TooltipBody from '@containers/Home/components/JourneyTile/components/TooltipBody';

import useMatchBreakpoint from '@hooks/useMatchBreakpoint';
import { breakpoint } from '@styles/breakpoints';

import { inject, observer } from 'mobx-react';

export { TilesRow } from '@containers/Home/components/JourneyTile/style';

const kindToTile = {
  standard: 'Standard Journey',
  occasional: 'Occasional Journey',
  short: 'Short Journey',
};

const JourneyTile = ({
  onClick,
  plan,
  plan: { kind, approximately },
  selected,
  commonsStore: { distanceUnitsLong },
}) => {
  const isTablet = useMatchBreakpoint(breakpoint.tabletPortrait);

  const tooltipProps = {
    ...(isTablet
      ? {
          placement: 'left',
          offset: [180, 12],
        }
      : {
          placement: 'bottom',
          offset: [0, -20],
        }),
  };

  return (
    <Box onClick={onClick} selected={selected}>
      <InfoTooltip
        {...tooltipProps}
        body={<TooltipBody plan={{ ...plan, name: kindToTile[plan.kind] }} />}
      >
        <InfoButton />
      </InfoTooltip>
      <Line selected={selected} />
      <Title>{kindToTile[kind]}</Title>
      <div>
        <Label>Approximately</Label>
        <Approximately
          value={parseFloat(approximately)}
          thousandSeparator=","
          suffix={` ${distanceUnitsLong} per year`}
          displayType="text"
        />
      </div>
    </Box>
  );
};

JourneyTile.defaultProps = {
  selected: false,
};

JourneyTile.propTypes = {
  onClick: PropTypes.func.isRequired,
  plan: PropTypes.shape({
    name: PropTypes.string,
    approximately: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  selected: PropTypes.bool,
  commonsStore: PropTypes.shape({
    distanceUnitsLong: PropTypes.string,
  }).isRequired,
};

export default inject('commonsStore')(observer(JourneyTile));
