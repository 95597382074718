import React from 'react';
import PropTypes from 'prop-types';

const Picture = ({ srcSet, srcSetMobile, srcSetTablet, alt }) => (
  <picture>
    <source
      media="(min-width: 768.1px)"
      srcSet={srcSet.map((img, index) => `${img} ${index + 1}x`).join(',')}
    />
    {srcSetTablet.length > 0 && (
      <source
        media="(min-width: 700.1px) and (max-width: 768px)"
        srcSet={srcSetTablet
          .map((img, index) => `${img} ${index + 1}x`)
          .join(',')}
      />
    )}
    {srcSetMobile.length > 0 && (
      <source
        media="(min-width: 320px) and (max-width: 700px)"
        srcSet={srcSetMobile
          .map((img, index) => `${img} ${index + 1}x`)
          .join(',')}
      />
    )}
    <img src={srcSet[0]} alt={alt} />
  </picture>
);

Picture.defaultProps = {
  alt: null,
  srcSetTablet: [],
  srcSetMobile: [],
  srcSet: [],
};

Picture.propTypes = {
  srcSet: PropTypes.array,
  srcSetMobile: PropTypes.array,
  srcSetTablet: PropTypes.array,
  alt: PropTypes.string,
};

export default Picture;
