import React from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import { Container } from '@styles/globalStyles';
import { breakpoint } from '@styles/breakpoints';
import CloseIcon from '@assets/images/icons/close.svg';

const LOGO_SIZE = '53px';
const LOGO_SIZE_MOBILE = '36px';

const SPACE_BETWEEN_RETAILER_NAME_AND_LOGO = '25px';
const SPACE_BETWEEN_RETAILER_NAME_AND_LOGO_MOBILE = '15px';

export const Content = styled(Container)`
  position: relative;
  color: #fff;
  display: flex;
  align-items: flex-end;

  @media ${breakpoint.tabletPortrait} {
    &:before {
      content: '';
      position: absolute;
      height: 1px;
      bottom: -100px;
      left: 45px;
      right: calc(97px + ${SPACE_BETWEEN_RETAILER_NAME_AND_LOGO});
      background: rgba(255, 255, 255, 0.4);
    }
  }

  @media ${breakpoint.md} {
    &:before {
      display: none;
    }
  }
`;

export const Logo = styled.div`
  position: absolute;
  right: 20px;
  top: 26px;
  width: ${LOGO_SIZE_MOBILE};
  height: ${LOGO_SIZE_MOBILE};

  img {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: opacity 0.5s ease-in-out;
    z-index: 1;

    &.dark {
      ${(props) => props.colorScheme === 'light' && 'opacity: 0;'}
    }

    &.light {
      z-index: 2;
      ${(props) => props.colorScheme === 'dark' && 'opacity: 0;'}
    }
  }

  @media ${breakpoint.tabletPortrait} {
    width: ${LOGO_SIZE};
    height: ${LOGO_SIZE};

    right: 45px;
    top: 30px;
  }

  @media ${breakpoint.md} {
    margin-bottom: 16px;

    position: relative;
    right: auto;
    top: auto;
  }
`;

export const Navigation = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  right: ${(props) => (props.mobileVisible ? 0 : '-100%')};
  top: 0;
  background: #fff;
  z-index: 10;
  transition: all 0.3s ease-in;
  padding: 0 30px;

  @media ${breakpoint.md} {
    display: flex;
    position: relative;
    width: auto;
    height: 95px;
    background: transparent;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    ${(props) =>
      props.colorScheme === 'light' &&
      'border-bottom-color: rgba(255,255,255,.4);'}
    ${(props) => props.colorScheme === 'dark' && 'border-bottom: none;'}
    flex: 1 0 auto;
    justify-content: flex-start;
    padding: 42px 0 0 20px;
    margin-right: ${SPACE_BETWEEN_RETAILER_NAME_AND_LOGO};
    opacity: 1;
    border-radius: 0;
    transform: none;
    left: 0;
    top: 0;
  }
`;

export const LinkItem = styled(({ colorScheme, ...props }) => (
  <NavLink {...props} />
))`
  position: relative;
  color: #262626;
  text-decoration: none;
  display: block;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 14px;
  transition: color 0.3s ease-in-out;
  margin-bottom: 25px;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: -10px;
    right: -10px;
    height: 1px;
    background: #e6e6e6;
  }

  @media ${breakpoint.md} {
    margin-bottom: 0;
    width: auto;
    color: ${(props) => props.colorScheme === 'light' && '#fff'};
    color: ${(props) => props.colorScheme === 'dark' && '#262626'};

    &:before {
      display: none;
    }
  }

  span {
    display: inline-block;
    padding: 20px 10px;
    border-bottom: 4px transparent solid;
    line-height: 1;

    @media ${breakpoint.md} {
      margin-bottom: 0;
      padding: 0 32px 33px 20px;
    }
  }

  &.active {
    span {
      border-bottom-color: ${({ theme }) => theme.colors.primary};
    }
  }

  &:hover {
    color: white;
  }
`;

export const BurgerLine = styled.rect`
  fill: #fff;
  width: 100%;
  height: 2px;
  transition: fill 0.5s ease-in-out;

  &:nth-of-type(2) {
    y: 5px;
  }
  &:nth-of-type(3) {
    y: 10px;
  }
`;

export const Burger = styled.button`
  display: block;
  position: absolute;
  left: 20px;
  top: 26px;
  z-index: 2;
  width: 32px;
  height: 32px;
  padding: 10px;
  border: none;
  border-radius: 100%;
  background-color: ${(props) =>
    props.colorScheme === 'dark' ? '#ffffff' : 'transparent'};
  transition: background-color 0.5s ease-in-out;

  &:focus {
    outline: none;
  }

  svg {
    display: block;
    width: 16px;
    height: 16px;
  }

  ${BurgerLine} {
    fill: ${(props) => props.colorScheme === 'dark' && '#4d4d4d'};
  }

  @media ${breakpoint.tabletPortrait} {
    left: 45px;
    top: 36px;
  }

  @media ${breakpoint.md} {
    display: none;
  }
`;

export const Close = styled.button`
  border: none;
  background: transparent url(${CloseIcon}) no-repeat 50% 50% / contain;
  height: 20px;
  width: 20px;

  display: block;

  &:focus {
    outline: none;
  }

  @media ${breakpoint.md} {
    display: none;
  }
`;

export const Wrapper = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  opacity: ${(props) => (props.navigationIsVisible ? 1 : 0)};

  ${(props) =>
    props.colorScheme === 'dark' &&
    css`
      position: static;
      background-color: #fff;
      height: 74px;
      border-bottom: 1px #e6e6e6 solid;

      ${Logo} {
        top: 20px;
      }

      ${Burger} {
        top: 22px;
      }

      @media ${breakpoint.tabletPortrait} {
        height: 95px;

        ${Burger} {
          top: 32px;
        }
      }

      @media ${breakpoint.md} {
        ${Logo} {
          top: auto;
        }
      }
    `};
`;

export const RetailerName = styled.div`
  position: absolute;
  right: calc(
    ${LOGO_SIZE_MOBILE} + ${SPACE_BETWEEN_RETAILER_NAME_AND_LOGO_MOBILE}
  );

  width: 100px;
  height: 36.5px;

  display: flex;
  align-items: center;

  font-size: 13px;
  text-align: right;

  color: white;

  &:hover {
    color: white;
  }

  @media ${breakpoint.tabletPortrait} {
    top: 9px;
    right: calc(${LOGO_SIZE} + ${SPACE_BETWEEN_RETAILER_NAME_AND_LOGO});
  }

  @media ${breakpoint.md} {
    right: calc(${LOGO_SIZE} + ${SPACE_BETWEEN_RETAILER_NAME_AND_LOGO});
  }
`;
