import styled from '@emotion/styled/macro';
import { breakpoint } from '@styles/breakpoints';

export const CarsRow = styled.div`
  display: flex;
  position: relative;

  padding-top: 12%;
  margin: 0 -8px;

  @media ${breakpoint.md} {
    padding-top: 64px;
  }
`;

export const VS = styled.svg`
  position: absolute;
  line-height: 1;
  z-index: 0;
  top: 0;
  height: 50%;

  @media ${breakpoint.md} {
    padding: 3% 0;
    top: 50%;
    left: 49%;
    transform: translate(-50%, -50%);
    height: auto;
    max-width: 28.5%;
  }
`;

export const Car = styled.div`
  position: relative;
  z-index: 1;

  flex: 1;
  text-align: center;
  padding: 0 8px;

  img {
    width: 100%;

    height: 100%;
    object-fit: contain;
    object-position: bottom;
  }

  @media ${breakpoint.md} {
    img {
      width: 75%;
    }
  }
`;

export const ResultRow = styled.div`
  margin: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media ${breakpoint.md} {
    flex-direction: row;
    margin: 16px 0;
  }
`;

export const Result = styled.div`
  margin-top: 32px;

  @media ${breakpoint.md} {
    margin-top: 16px;
    width: 25%;
    min-width: 280px;
    display: flex;
    flex-direction: column;

    &:last-of-type {
      margin-left: 8%;
    }
  }
`;

export const Kind = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
  text-transform: capitalize;
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[4]}px;
  flex: 1;
`;

export const Value = styled.div`
  padding: 16px 0;
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.gray};

  &:not(:last-of-type) {
    border-bottom: 1px solid #e6e6e6;
  }

  strong {
    color: ${({ theme }) => theme.colors.text};
  }
`;
