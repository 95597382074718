import toSlug from '@utils/toSlug';

function toSlugMap(bev, phev, key) {
  const out = {};
  const types = {
    bev,
    phev,
  };
  Object.keys(types).forEach((type) => {
    types[type].forEach((group) => {
      const multipleSeries = group.series.length > 1;
      group.series.forEach((series, seriesIndex) => {
        series.vehicles.forEach((car, carIndex) => {
          car.type = type;
          car.group = group.name;
          car.index = multipleSeries ? seriesIndex : carIndex;
          out[toSlug(car[key])] = car;
        });
      });
    });
  });
  return out;
}

export default toSlugMap;
