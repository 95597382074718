import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.div`
  padding: 25px 0;
  flex: 1;
  margin: 8px;

  background-color: #f8f8f8;
  border: 1px solid #e6e6e6;

  text-align: center;

  @media ${breakpoint.tabletPortrait} {
    padding: 20px 0;
  }

  @media ${breakpoint.md} {
    padding: 35px 0;
  }
`;

export const Border = styled.div`
  transition: width 0.3s ease-in-out;
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #a7a7a7;
`;

export const Value = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[5]}px;
  line-height: 1;
  margin-bottom: 10px;
  display: inline-flex;
  position: relative;
  align-items: flex-start;

  @media ${breakpoint.md} {
    font-size: ${({ theme }) => theme.fontSizes[6]}px;

    margin-bottom: 20px;
  }
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: normal;
  display: block;
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.gray};

  @media ${breakpoint.tabletPortrait} {
    font-size: 12px;
  }

  @media ${breakpoint.md} {
    font-size: 14px;
  }
`;
