import preset from '@rebass/preset';

export default {
  ...preset,
  buttons: {
    ...preset.buttons,
    text: {
      bg: 'transparent',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      color: '#bbb',
      fontFamily: 'BMW-Bold',
      padding: 0,

      '&:focus': {
        outline: 'none',
      },

      '&:disabled': {
        cursor: 'default',
      },

      img: {
        display: 'block',
        marginRight: '10px',
      },
    },
    primary: {
      backgroundColor: '#1c69d4',
      fontFamily: 'BMW-Bold',
      color: '#fff',
      minWidth: '285px',
      textAlign: 'center',
      padding: '17px 15px',
      lineHeight: '16px',
      fontSize: '14px',
      borderRadius: 0,
      transition: 'background-color .3s ease-in-out',
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: '#0653B6',
      },
      '&:focus': {
        outline: 'none',
      },
    },
    secondary: {
      background: '#4d4d4d',
      color: '#fff',
      cursor: 'pointer',
      padding: '17px 15px',
      lineHeight: '16px',
      fontSize: '14px',
      fontFamily: 'BMW-Bold',
      minWidth: '285px',
      textAlign: 'center',
      borderRadius: 0,
      transition: 'background-color .3s ease-in-out',

      '&:hover': {
        backgroundColor: '#262626',
      },
      '&:disabled': {
        opacity: 0.3,
        cursor: 'default',
      },
      '&:focus': {
        outline: 'none',
      },
    },
  },
  colors: {
    text: '#262626',
    background: '#fff',
    primary: '#1c69d4',
    secondary: '#bbbbbb',
    lightGray: '#bbbbbb',
    gray: '#8e8e8e',
    darkGray: '#666666',
    muted: '#575757',
    highlight: 'hsla(205, 100%, 40%, 0.125)',
  },
  fonts: {
    regular: 'BMW-Regular',
    body: 'BMW-Light',
    heading: 'BMW-Light',
    monospace: 'BMW-Light',
    bold: 'BMW-Bold',
    light: 'BMW-Light',
    webBold: 'BMW-Type-Web-Bold',
  },
  fontWeights: {
    thin: 300,
    body: 400,
    heading: 900,
    bold: 700,
    medium: 500,
  },
  fontSizes: [12, 15, 18, 20, 25, 35, 50],
  lineHeights: [14, 17, 21, 25, 30, 41, 58],
  space: [0, 4, 8, 16, 32, 64, 85, 128, 256, 512],
  layoutMaxWidth: '1244px', // 1184px + 60px paddings
  banners: {
    maxWidth: 1366,
    height: 516,
    heightTablet: 380,
    heightMobile: 178,
  },
};
