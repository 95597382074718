import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import { Radio, Select } from '@components/Form';
import {
  FUEL_TYPES,
  SAVING_PERIODS,
  SAVING_TYPES,
} from '@root/stores/calculatorStore';

import {
  RadioGroup,
  RadioGroupIreland,
  RadioGroupGroup,
  RadioGroupGroupIreland,
  RadioGroupLabel,
  SelectGroup,
  SelectGroupGroup,
  SelectGroupLabel,
  FormGrid,
  SavingsType,
  SavingsTypeItem,
  FuelTypeItems,
  FuelTypeItem,
  SavingsPeriodItems,
  SavingsPeriodItem,
} from '@containers/Home/components/BevForm/style';

const BevForm = ({
  calculatorStore: { bevForm, setBevFormValue, calculationParams },
  commonsStore: { isIrelandSite },
}) => {
  const onInputValueChange = ({ target }) => {
    setBevFormValue(target.name, target.value);
  };

  const onOptionChange = (key, option) => {
    if (option.value !== bevForm[key]?.value) {
      setBevFormValue(key, option.value);
    }
  };

  const distanceCoverageSelector = useMemo(
    () => `${bevForm.period}_distance_coverage`,
    [bevForm.period],
  );

  const savingsPeriodOptions = (
    <>
      <RadioGroupLabel>Savings Period</RadioGroupLabel>
      <SavingsPeriodItems>
        <SavingsPeriodItem>
          <Radio
            onChange={onInputValueChange}
            active={bevForm.period}
            value={SAVING_PERIODS.ANNUAL}
            label="Annual"
            name="period"
          />
        </SavingsPeriodItem>
        <SavingsPeriodItem>
          <Radio
            onChange={onInputValueChange}
            active={bevForm.period}
            value={SAVING_PERIODS.MONTHLY}
            label="Monthly"
            name="period"
          />
        </SavingsPeriodItem>
      </SavingsPeriodItems>
    </>
  );

  const fuelTypeOptions = (
    <>
      <RadioGroupLabel>Fuel Type</RadioGroupLabel>
      <FuelTypeItems>
        <FuelTypeItem>
          <Radio
            onChange={onInputValueChange}
            active={bevForm.fuel_type}
            value={FUEL_TYPES.PETROL}
            label="Petrol"
            name="fuel_type"
          />
        </FuelTypeItem>
        <FuelTypeItem>
          <Radio
            onChange={onInputValueChange}
            active={bevForm.fuel_type}
            value={FUEL_TYPES.DIESEL}
            label="Diesel"
            name="fuel_type"
          />
        </FuelTypeItem>
      </FuelTypeItems>
    </>
  );

  const options = (
    <>
      <RadioGroup>{savingsPeriodOptions}</RadioGroup>
      <RadioGroup>{fuelTypeOptions}</RadioGroup>
    </>
  );

  const optionsIreland = (
    <>
      <RadioGroupIreland>{savingsPeriodOptions}</RadioGroupIreland>
      <RadioGroupIreland>{fuelTypeOptions}</RadioGroupIreland>
    </>
  );

  return (
    <FormGrid>
      {isIrelandSite && (
        <RadioGroupGroupIreland>{optionsIreland}</RadioGroupGroupIreland>
      )}
      {!isIrelandSite && <RadioGroupGroup>{options}</RadioGroupGroup>}
      {!isIrelandSite && (
        <SavingsType>
          <RadioGroupLabel>Savings Type</RadioGroupLabel>
          <SavingsTypeItem>
            <Radio
              onChange={onInputValueChange}
              active={bevForm.saving_type}
              value={SAVING_TYPES.STANDARD}
              label="Home Charging"
              name="saving_type"
            />
          </SavingsTypeItem>
          <SavingsTypeItem>
            <Radio
              onChange={onInputValueChange}
              active={bevForm.saving_type}
              value={SAVING_TYPES.IONITY}
              label={
                <>
                  BMW Charging<sup>&dagger;</sup>
                </>
              }
              name="saving_type"
            />
          </SavingsTypeItem>
          <SavingsTypeItem>
            <Radio
              onChange={onInputValueChange}
              active={bevForm.saving_type}
              value={SAVING_TYPES.SMART}
              label="Smart Tariff"
              name="saving_type"
            />
          </SavingsTypeItem>
        </SavingsType>
      )}
      <SelectGroupGroup>
        <SelectGroup>
          {!isIrelandSite && (
            <SelectGroupLabel>Mileage Details</SelectGroupLabel>
          )}
          <Select
            options={calculationParams[distanceCoverageSelector].values}
            label={calculationParams[distanceCoverageSelector].label}
            value={bevForm[distanceCoverageSelector]}
            onChange={(value) =>
              onOptionChange(distanceCoverageSelector, value)
            }
          />
          <Select
            options={calculationParams.fuel_economy.values}
            label={calculationParams.fuel_economy.label}
            value={bevForm.fuel_economy}
            onChange={(value) => onOptionChange('fuel_economy', value)}
          />
        </SelectGroup>
        <SelectGroup>
          {!isIrelandSite && <SelectGroupLabel>Energy Costs</SelectGroupLabel>}
          {bevForm.fuel_type === FUEL_TYPES.PETROL ? (
            <Select
              options={calculationParams.petrol_cost.values}
              label={calculationParams.petrol_cost.label}
              value={bevForm.petrol_cost}
              onChange={(value) => onOptionChange('petrol_cost', value)}
            />
          ) : (
            <Select
              options={calculationParams.diesel_cost.values}
              label={calculationParams.diesel_cost.label}
              value={bevForm.diesel_cost}
              onChange={(value) => onOptionChange('diesel_cost', value)}
            />
          )}
          {bevForm.saving_type === SAVING_TYPES.STANDARD && (
            <Select
              options={calculationParams.electricity_cost.values}
              label={calculationParams.electricity_cost.label}
              value={bevForm.electricity_cost}
              onChange={(value) => onOptionChange('electricity_cost', value)}
            />
          )}
          {bevForm.saving_type === SAVING_TYPES.IONITY && (
            <Select
              options={calculationParams.bmw_charging.values}
              label={calculationParams.electricity_cost.label}
              value={bevForm.bmw_charging}
              onChange={(value) => onOptionChange('bmw_charging', value)}
            />
          )}
          {bevForm.saving_type === SAVING_TYPES.SMART && (
            <Select
              options={calculationParams.smart_tariff.values}
              label={calculationParams.electricity_cost.label}
              value={bevForm.smart_tariff}
              onChange={(value) => onOptionChange('smart_tariff', value)}
            />
          )}
        </SelectGroup>
      </SelectGroupGroup>
    </FormGrid>
  );
};

BevForm.propTypes = {
  calculatorStore: PropTypes.shape({
    bevForm: PropTypes.object,
    calculationParams: PropTypes.object,
    setBevFormValue: PropTypes.func,
  }).isRequired,
};

export default inject('calculatorStore', 'commonsStore')(observer(BevForm));
