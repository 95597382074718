import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import {
  GroupsTab,
  NavWrapper,
} from '@containers/Home/components/CarsNav/style';
import { Container } from '@styles/globalStyles';

import { TabsRow, Tab } from '@components/Tabs/style';
import { KINDS } from '@root/stores/carsStore';

const CarsNav = ({
  carsStore: {
    carKinds,
    activeGroup,
    activeKind,
    setActiveKind,
    setActiveGroup,
    carsNavIsVisible,
  },
}) => (
  <NavWrapper isVisible={carsNavIsVisible}>
    <Container>
      <TabsRow>
        {Object.entries(carKinds).map(([key, tab], index) => (
          <Tab
            key={`kinds-tab_${index}`}
            onClick={() => setActiveKind(key)}
            active={key === activeKind}
          >
            {tab.text}
          </Tab>
        ))}
      </TabsRow>
      <TabsRow>
        {carKinds[activeKind].groups.map(({ text, id }, index) => (
          <GroupsTab
            key={`kinds-tab_${index}`}
            onClick={() => setActiveGroup(id)}
            active={id === activeGroup}
          >
            {text}
          </GroupsTab>
        ))}
      </TabsRow>
    </Container>
  </NavWrapper>
);

CarsNav.propTypes = {
  carsStore: PropTypes.shape({
    carKinds: PropTypes.object,
    cars: PropTypes.object,
    activeKind: PropTypes.oneOf(Object.values(KINDS)),
    activeCar: PropTypes.object,
    setActiveKind: PropTypes.func,
    setActiveGroup: PropTypes.func,
    carsNavIsVisible: PropTypes.bool,
  }).isRequired,
};

export default inject('carsStore')(observer(CarsNav));
