import axios from 'axios';
import useRuntimeEnvironmentVariables from '@hooks/useRuntimeEnvironmentVariables';

const API = axios.create({
  baseURL: useRuntimeEnvironmentVariables().REACT_APP_API_URL,
});

const APIRoutes = {
  COMMONS: ({ dealerNumber }) =>
    [
      '/v1/pages/common',
      dealerNumber ? `?dealer_number=${dealerNumber}` : '',
    ].join(''),
  PAGES: (page, dealerNumber) =>
    [
      `v1/pages/${page}`,
      dealerNumber ? `?dealer_number=${dealerNumber}` : '',
    ].join(''),
  CARS: ({ dealerNumber }) =>
    [`v1/vehicles`, dealerNumber ? `?dealer_number=${dealerNumber}` : ''].join(
      '',
    ),
  CHARGING_POINTS: '/v1/charging_points',
  CHARGING_POINT_INFO: (id) => `/v1/charging_points/${id}`,
  PHEV_SAVINGS: (id) => `/v1/vehicles/${id}/phev_savings`,
  BEV_SAVINGS: (id) => `/v1/vehicles/${id}/electric_savings`,
};

export { API, APIRoutes };
