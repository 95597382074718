import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '@components/Form/style';

import * as Styled from '@components/Form/Checkbox/style';

const Checkbox = ({ label, checked, onChange, ...inputProps }) => (
  <Styled.Checkbox>
    <input
      type="checkbox"
      checked={checked}
      onChange={onChange}
      {...inputProps}
    />
    <Label>{label}</Label>
    <Styled.CheckMark />
  </Styled.Checkbox>
);

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default Checkbox;
