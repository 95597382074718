import React from 'react';
import PropTypes from 'prop-types';
import VudooVideo from '@components/VudooVideo';

import { SectionText, Wrapper, VideoWrapper } from './video-section-style';

const VideoSection = ({ section }) => (
  <Wrapper image={section.image}>
    <SectionText content={section.description} title={section.title} />
    <VideoWrapper>
      <VudooVideo link={section.uri} />
    </VideoWrapper>
  </Wrapper>
);

VideoSection.propTypes = {
  section: PropTypes.object.isRequired,
};

export default VideoSection;
