import styled from '@emotion/styled/macro';
import { breakpoint } from '@styles/breakpoints';
import { FlexRow, RESET_BUTTON } from '@styles/globalStyles';

export const FormContainer = styled.div`
  width: 100%;
  margin: 34px auto 0;
`;

export const BoxRow = styled(FlexRow)`
  padding: ${(props) => (props.noPadding ? '0px' : '36px 0px 30px')};
  justify-content: center;

  @media ${breakpoint.tabletPortrait} {
    margin: 0 -8px;
    flex-wrap: wrap;
  }

  @media ${breakpoint.md} {
    padding: ${(props) => (props.noPadding ? '0px' : '50px 10px 30px')};
  }
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  overflow: hidden;
  padding: ${(props) => (props.noPadding ? '0px' : '66px 0px 10px')};

  & > input::placeholder {
    color: grey;
  }

  @media ${breakpoint.tabletPortrait} {
    justify-content: center;
    padding: ${(props) => (props.noPadding ? '0px' : '50px 10px 50px')};
  }
`;

export const BoxWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 404px;
  min-width: 335px;
`;

export const Box = styled.div`
  position: relative;
  width: 100%;
  padding: 20px;
  border: 2px solid #e6e6e6;
  margin: 8px 0;

  @media ${breakpoint.tabletPortrait} {
    width: calc(50% - 16px);
    margin: 8px;
    padding: 28px;
  }

  @media ${breakpoint.md} {
    max-width: 385px;
    padding: 38px;
  }
`;

export const ArrowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2px 0px;
  width: 100%;
`;

export const ArrowTopRight = styled.div`
  width: 0;
  height: 0;
  border-top: 10px solid #4d4d4d;
  border-right: 10px solid transparent;
`;

export const ArrowBottomLeft = styled.div`
  width: 0;
  height: 0;
  border-bottom: 10px solid #4d4d4d;
  border-left: 10px solid transparent;
`;

export const LineTop = styled.div`
  width: 100%;
  height: 0;
  border-top: 5px solid #4d4d4d;
  border-bottom: 5px solid transparent;
`;

export const LineBottom = styled.div`
  width: 100%;
  height: 0;
  border-bottom: 5px solid #4d4d4d;
  border-top: 5px solid transparent;
`;

export const ArrowIcon = styled.div`
  cursor: pointer;

  &:hover {
    ${ArrowTopRight} {
      border-top: 10px solid #1c69d4;
    }
    ${ArrowBottomLeft} {
      border-bottom: 10px solid #1c69d4;
    }
    ${LineTop} {
      border-top: 5px solid #1c69d4;
    }
    ${LineBottom} {
      border-bottom: 5px solid #1c69d4;
    }
  }
`;

export const Title = styled.div`
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.bold};
  color: #262626;
`;

export const Arrow = styled.img`
  width: 48px;
  height: 50px;
  border: 2px solid #bbbbbb;
  padding: 15px;
  margin: 14px 0px 14px -2px;
`;

export const ReverseButton = styled.button`
  ${RESET_BUTTON()}

  margin-top: 8px;
  margin-bottom: 8px;

  svg {
    width: 40px;

    path {
      fill: ${(props) => props.theme.colors.lightGray};
    }
  }

  &:hover {
    svg {
      path {
        fill: ${(props) => props.theme.colors.darkGray};
      }
    }
  }

  @media ${breakpoint.tabletPortrait} {
    margin-bottom: 0;

    svg {
      width: 30px;
    }
  }
`;

export const MiddleColumn = styled.div`
  text-align: center;

  @media ${breakpoint.tabletPortrait} {
    order: unset;
    order: 1;
    width: 100%;
  }
`;
