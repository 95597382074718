import React from 'react';
import { shape, string } from 'prop-types';

import {
  Wrapper,
  Header,
  Row,
  Label,
  Value,
  TextRow,
  AnnualRow,
} from '@containers/Home/components/JourneyTile/components/style';

import { inject, observer } from 'mobx-react';

const TileTooltipBody = ({
  plan: {
    name,
    description,
    annualDistanceCoverage,
    dailyJourneyDesc,
    dailyJourneyValue,
    occasionalAverageJourneyDesc,
    occasionalAverageJourneyValue,
    occasionalLongJourneyDesc,
    occasionalLongJourneyValue,
    parkedFor,
  },
  commonsStore: { distanceCoverageUnits },
}) => (
  <Wrapper>
    <Header>
      <h2>{name}</h2>
      <p>{description}</p>
    </Header>
    <AnnualRow>
      <TextRow>
        <Label>Annual {distanceCoverageUnits}</Label>
        <Value>{annualDistanceCoverage}</Value>
      </TextRow>
    </AnnualRow>
    <Row>
      <Label>Daily journey</Label>
      <TextRow>
        <span>{dailyJourneyDesc}</span>
        <Value>{dailyJourneyValue.toLocaleString()}</Value>
      </TextRow>
    </Row>
    <Row>
      <Label>Occasional long journey</Label>
      <TextRow>
        <span>{occasionalLongJourneyDesc}</span>
        <Value>{occasionalLongJourneyValue.toLocaleString()}</Value>
      </TextRow>
    </Row>
    <Row>
      <Label>Occasional average journey</Label>
      <TextRow>
        <span>{occasionalAverageJourneyDesc}</span>
        <Value>{occasionalAverageJourneyValue.toLocaleString()}</Value>
      </TextRow>
    </Row>
    <Row>
      <TextRow>
        <Label hideMargin>
          Your car is stationary and parked for the equivalent of:
        </Label>
        <Value>{parkedFor}</Value>
      </TextRow>
    </Row>
  </Wrapper>
);

TileTooltipBody.propTypes = {
  plan: shape({
    name: string,
    description: string,
    annualDistanceCoverage: string,
    dailyJourney: string,
    occasionalLongJourney: string,
    occasionalAverageJourney: string,
    parkedFor: string,
  }).isRequired,
  commonsStore: shape({
    distanceCoverageUnits: string,
  }).isRequired,
};

export default inject('commonsStore')(observer(TileTooltipBody));
