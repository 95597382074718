import React from 'react';
import styled from '@emotion/styled/macro';
import { Global, css } from '@emotion/react';
import { HEADING, RESET_BUTTON } from '@styles/globalStyles';
import { Flex } from 'rebass';
import { breakpoint } from '@styles/breakpoints';

export const Background = styled.div`
  background: #f8f8f8;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 283px;

  z-index: -1;
  ${({ gradientStart, gradientEnd }) => `
  background: linear-gradient(180deg, ${gradientStart} 0%, ${gradientEnd} 100%)
  `};

  @media ${breakpoint.tabletPortrait} {
    height: 341px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const MainRow = styled(Flex)`
  padding-bottom: 16px;

  @media ${breakpoint.md} {
    height: 360px;
  }
`;

export const CarWrapper = styled.div`
  flex: 1.4;
  display: flex;
`;

export const Car = styled.img`
  flex: 1;
  width: 100%;
  display: block;
  margin: 0 auto;
  padding: 0 5%;

  object-fit: contain;

  @media ${breakpoint.md} {
    max-width: 530px;
    padding: 0 10px;
    align-self: flex-end;
  }

  @media ${breakpoint.xl} {
    max-width: 630px;
    padding: 0 20px;
  }
`;

export const DetailsWrapper = styled.div`
  flex: 1;

  @media ${breakpoint.md} {
    min-width: 400px;
    padding: 50px 0 0 50px;
    color: white;
  }
`;

export const TextWrapper = styled.div`
  padding: 36px 32px;
  color: white;

  @media ${breakpoint.md} {
    padding: 0;
  }
`;

export const Type = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 14px;

  margin-bottom: 12px;
`;

export const Model = styled.div`
  ${({ theme }) => HEADING(theme)};
  font-size: ${({ theme }) => theme.fontSizes[5]}px;
  margin-bottom: 12px;
  margin-left: -2px;

  @media ${breakpoint.md} {
    white-space: pre;
  }
`;

export const Price = styled.div`
  font-family: ${({ theme }) => theme.fonts.light};
  font-size: ${({ theme }) => theme.fontSizes[3]}px;
`;

export const Info = styled.img`
  margin-left: 20px;
`;

export const InfoTooltipBody = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[0]}px;
  line-height: 16px;
  padding: 8px;

  @media ${breakpoint.tabletPortrait} {
    max-width: 420px;
    padding: 18px;
  }

  @media ${breakpoint.md} {
    max-width: 460px;
  }
`;

export const ToggleButton = styled.button`
  ${RESET_BUTTON()}

  font-family: ${({ theme }) => theme.fonts.bold};
  color: inherit;
  font-size: 14px;

  display: flex;
  align-items: center;

  svg {
    margin-right: 16px;
    transform: rotate(${({ isOpen }) => (isOpen ? '270deg' : '90deg')});
    transition: transform 0.4s;

    path {
      fill: #1c69d4;
    }
  }

  @media ${breakpoint.md} {
    padding-left: 12px;
    transform: unset;

    svg {
      margin-right: 8px;
      transform: translateX(-12px);

      path {
        fill: #ffffff;
      }
    }

    &:hover {
      svg {
        transform: translateX(0) rotate(0);
      }
    }

    ${({ isOpen }) =>
      isOpen &&
      `
      svg {
        transform: translateX(-12px) rotate(90deg);
      }
      `}
`;

export const ValuesRow = styled(Flex)`
  margin: 30px 0;
  flex-direction: column;

  @media ${breakpoint.tabletPortrait} {
    max-width: 504px;
  }

  @media ${breakpoint.md} {
    flex-direction: row;
    max-width: unset;
  }
`;

export const ValueItem = styled.div`
  font-family: ${({ theme }) => theme.fonts.light};
  padding: 16px 10px;

  display: flex;
  justify-content: space-between;

  &:not(:last-of-type) {
    border-bottom: 1px solid #e6e6e6;
  }

  span {
    display: block;
    font-size: 15px;

    &:first-of-type {
      font-size: 18px;
    }
  }

  @media ${breakpoint.md} {
    display: block;
    padding: 0 16px 0 0;
    color: #f8f8f8;

    &:not(:last-of-type) {
      border-right: 1px solid #ffffff;
      border-bottom: unset;
      margin-right: 16px;
    }

    span {
      display: block;
      font-size: 13px;

      &:first-of-type {
        font-size: 16px;
      }
    }
  }
`;

export const Wrapper = styled.div`
  margin-top: -20px;
  margin-bottom: 80px;
`;

export const DetailsContainer = styled.div`
  position: relative;

  &.details-transition-enter {
    opacity: 0;

    ${Background} {
      width: 50%;
    }

    ${Car} {
      transform: translate(-60%) scale(0.8);
    }
  }

  &.details-transition-enter-active {
    opacity: 1;
    transition: opacity 1.5s ease;

    ${Background} {
      width: 100%;
      transition: all 0.75s ease;
    }

    ${Car} {
      transform: translate(0) scale(1);
      transition: all 0.75s ease;
    }
  }

  &.details-transition-exit {
    opacity: 1;

    ${Background} {
      width: 100%;
    }

    ${Car} {
      transform: translate(0) scale(1);
    }
  }

  &.details-transition-exit-active {
    opacity: 0;
    transition: opacity 1.5s ease;

    ${Background} {
      width: 50%;
      transition: all 0.75s ease;
    }

    ${Car} {
      transform: translate(-60%) scale(0.8);
      transition: all 0.75s ease;
    }
  }
`;

export const OverviewWrapper = styled.div`
  overflow: hidden;
`;

export const OverviewTitle = styled.div`
  ${({ theme }) => HEADING(theme)};
  font-size: ${({ theme }) => theme.fontSizes[3]}px;
  text-transform: uppercase;

  margin-top: 24px;
  margin-bottom: 8px;

  @media ${breakpoint.md} {
    font-size: ${({ theme }) => theme.fontSizes[4]}px;
    margin-top: unset;
    margin-bottom: 16px;
  }
`;

export const Description = styled.div`
  margin-bottom: 24px;
`;

export const DetailsColumn = styled.div`
  width: 100%;

  @media ${breakpoint.tabletPortrait} {
    width: calc(50% - 20px);

    &:not(:last-of-type) {
      margin-right: 20px;
    }
  }

  @media ${breakpoint.md} {
    width: 100%;
    max-width: 400px;
    &:not(:last-of-type) {
      margin-right: 100px;
    }
  }
`;

export const DetailsItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 8px;
  font-size: 14px;

  border-bottom: 1px solid #e6e6e6;

  b {
    font-family: ${({ theme }) => theme.fonts.bold};
    color: ${({ theme }) => theme.colors.gray};
  }
`;

export const BrochureRequestButton = styled.div`
  margin-top: 35px;
`;

export const OverviewTransitions = () => (
  <Global
    styles={css`
      .overview-transition-enter {
        opacity: 0;
        max-height: 0;
      }

      .overview-transition-enter-active {
        opacity: 1;
        max-height: 500px;
        transition: opacity 1s, max-height 0.6s;

        @media ${breakpoint.tabletPortrait} {
          max-height: 300px;
        }
      }

      .overview-transition-exit {
        opacity: 1;
        max-height: 500px;

        @media ${breakpoint.tabletPortrait} {
          max-height: 300px;
        }
      }

      .overview-transition-exit-active {
        opacity: 0;
        max-height: 0;
        transition: opacity 0.6s, max-height 1s;
      }
    `}
  />
);
