import styled from '@emotion/styled/macro';
import { breakpoint } from '@styles/breakpoints';

export const InputWrapper = styled.div`
  margin-bottom: 20px;

  @media ${breakpoint.tabletPortrait} {
    margin: 16px 8px;
  }
`;

export const Value = styled.span`
  color: #c1c1c1;
  font-size: 12px;
  margin-top: 2px;
`;
