/* eslint-disable max-len */
import React from 'react';
import { object, shape, string } from 'prop-types';
import { inject, observer, PropTypes } from 'mobx-react';
import { Box } from 'rebass';
import useMatchBreakpoint from '@root/hooks/useMatchBreakpoint';
import { breakpoint } from '@styles/breakpoints';
import {
  CarsRow,
  Car,
  ResultRow,
  Result,
  Kind,
  Title,
  Value,
  VS,
} from '@containers/Home/components/CarCompare/style';
import { FUEL_TYPES } from '@root/stores/calculatorStore';

const CarCompare = ({
  carsStore: { activeCar, carKinds, activeKind, equivalents },
  calculatorStore: {
    phevForm: { fuelType },
  },
  commonsStore: { asCurrency, isIrelandSite },
}) => {
  const isDesktop = useMatchBreakpoint(breakpoint.md);

  let compareToVehicle = null;

  if (fuelType === FUEL_TYPES.PETROL && equivalents.petrol) {
    compareToVehicle = equivalents.petrol[activeCar.petrolEquivalentId];
  } else if (fuelType === FUEL_TYPES.DIESEL && equivalents.diesel) {
    compareToVehicle = equivalents.diesel[activeCar.dieselEquivalentId];
  }

  const carsToDisplay = [
    { kind: carKinds[activeKind].text, ...activeCar },
    { kind: fuelType, ...compareToVehicle },
  ];

  if (compareToVehicle) {
    return (
      <div>
        <CarsRow>
          <VS
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 326.416 193.395"
          >
            <path
              d="M-.075,8.607,65.944,193.658h46.631L178.595,8.607H139.081L89.505,150.218,40.175,8.607Zm200.758,88.6c7.117,5.89,15.462,9.326,28.224,13.5l31.66,10.062c10.308,3.191,19.634,6.381,23.315,9.326s5.154,7.363,5.154,13.253c0,6.381-2.209,11.29-7.363,14.726-5.4,3.681-13.253,5.154-27.488,5.154-20.37,0-47.613-5.4-67.247-9.326v35.832c17.18,3.191,43.686,8.1,64.3,8.1,27.242,0,43.44-4.418,56.448-14.726,12.762-10.062,18.652-24.052,18.652-41.722,0-15.953-5.645-29.942-15.462-38.286-7.608-6.626-17.916-10.062-31.66-14.48L247.314,78.553c-10.062-3.191-15.216-4.909-19.634-8.1-3.927-2.945-5.154-7.117-5.154-13.5,0-5.645,1.227-9.572,5.89-13.253,5.154-3.927,12.517-4.663,25.279-4.663,16.444,0,48.1,6.872,64.792,10.553V14.252c-16.444-3.681-44.913-9.817-66.265-9.817-19.879,0-36.078,2.209-47.858,11.29-13.253,10.062-19.143,23.561-19.143,43.686C185.221,75.608,190.13,88.37,200.683,97.206Z"
              transform="translate(0.075 -4.435)"
              fill="#e6e6e6"
            />
          </VS>
          {carsToDisplay.map(
            ({ mainImage, mainImageMobile, fullName, name }, index) => (
              <Car key={`compare-car_${index}`}>
                <img
                  src={isDesktop ? mainImage : mainImageMobile}
                  alt={fullName || name}
                />
              </Car>
            ),
          )}
        </CarsRow>
        <ResultRow>
          {carsToDisplay.map(
            ({ kind, fullName, name, price, benefitInKind }, index) => (
              <Result key={`compare-result_${index}`}>
                <Kind>{kind}</Kind>
                <Title>{fullName || name}</Title>
                <Value>
                  On the road price:{' '}
                  <strong>{asCurrency(parseFloat(price))}</strong>
                </Value>
                {isIrelandSite ? null : (
                  <Value>
                    Benefit in kind: <strong>{benefitInKind}</strong>
                  </Value>
                )}
              </Result>
            ),
          )}
        </ResultRow>
      </div>
    );
  }

  return (
    <Box textAlign="center" my={4}>
      No data do display
    </Box>
  );
};

CarCompare.defaultProps = {};

CarCompare.propTypes = {
  carsStore: shape({
    activeCar: object,
    carKinds: object,
    activeKind: string,
    equivalents: object,
  }).isRequired,
  commonsStore: shape({
    asCurrency: PropTypes.func,
    isIrelandSite: PropTypes.bool,
  }).isRequired,
};

export default inject(
  'carsStore',
  'calculatorStore',
  'commonsStore',
)(observer(CarCompare));
