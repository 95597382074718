import React from 'react';
import { array, oneOf, string } from 'prop-types';
import { Box } from 'rebass';

import Picture from '@components/Picture';
import { LinkButton } from '@styles/globalStyles';

import {
  BannerContent,
  BannerText,
  Wrapper,
  BannerImage,
  Header,
  Column,
  ColumnImage,
  ColumnSection,
} from '@components/Banner/style';

import ReactGA from 'react-ga4';

export { BannersRow } from '@components/Banner/style';

export const BANNER_TYPES = {
  FULL_WIDTH: 'fullwidth',
  BROCHURE: 'brochure',
  WITH_IMAGE: 'withImage',
  DEFAULT: null,
};

export const BANNER_VARIANTS = {
  CHARGING: 'CHARGING',
  COLUMN: 'COLUMN',
  DEFAULT: 'DEFAULT',
  BENEFIT: 'BENEFIT',
  PROMO: 'PROMO',
};

const Banner = ({
  title,
  description,
  bannerType,
  buttonText,
  buttonUrl,
  imageDesktop,
  imageMobile,
  imageTablet,
  variant,
}) => {
  const fullWidth = bannerType === BANNER_TYPES.FULL_WIDTH;
  const brochure = bannerType === BANNER_TYPES.BROCHURE;
  const benefit =
    variant === BANNER_VARIANTS.BENEFIT || variant === BANNER_VARIANTS.PROMO;

  const handleLinkButtonClick = brochure
    ? () =>
        ReactGA.event({
          category: 'Brochure request link-button',
          action: 'BrochureRequest',
          label: 'Link-button in the digital model brochure card',
          transport: 'beacon',
        })
    : undefined;

  if (variant === BANNER_VARIANTS.COLUMN) {
    return (
      <Column>
        <ColumnImage>
          {imageDesktop ? (
            <Picture
              alt={title}
              srcSet={imageDesktop}
              srcSetMobile={imageMobile}
              srcSetTablet={imageTablet}
            />
          ) : null}
        </ColumnImage>
        <ColumnSection>
          <Header>{title}</Header>
          <BannerText
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
          {buttonText && (
            <Box mt="30px">
              <LinkButton
                variant="primary"
                href={buttonUrl}
                target="_blank"
                onClick={handleLinkButtonClick}
              >
                {buttonText}
              </LinkButton>
            </Box>
          )}
        </ColumnSection>
      </Column>
    );
  }

  return (
    <Wrapper
      fullWidth={fullWidth}
      benefit={benefit}
      charging={variant === BANNER_VARIANTS.CHARGING}
    >
      <BannerContent>
        <Header>{title}</Header>
        <BannerText dangerouslySetInnerHTML={{ __html: description }} />
        {buttonText && (
          <Box mt="30px">
            <LinkButton
              href={buttonUrl}
              target="_blank"
              variant={fullWidth && !benefit ? 'secondary' : 'primary'}
              onClick={handleLinkButtonClick}
            >
              {buttonText}
            </LinkButton>
          </Box>
        )}
      </BannerContent>

      <BannerImage>
        {imageDesktop ? (
          <Picture
            alt={title}
            srcSet={imageDesktop}
            srcSetMobile={imageMobile}
            srcSetTablet={imageTablet}
          />
        ) : null}
      </BannerImage>
    </Wrapper>
  );
};

Banner.defaultProps = {
  bannerType: BANNER_TYPES.WITH_IMAGE,
  variant: BANNER_VARIANTS.DEFAULT,
};

Banner.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  bannerType: oneOf(Object.values(BANNER_TYPES)),
  buttonText: string.isRequired,
  buttonUrl: string.isRequired,
  imageDesktop: array.isRequired,
  imageMobile: array.isRequired,
  imageTablet: array.isRequired,
  variant: oneOf(Object.values(BANNER_VARIANTS)),
};

export default Banner;
