/* eslint-disable max-len */
import React from 'react';

export default {
  facebook: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.998"
      height="32.889"
      viewBox="0 0 32.998 32.889"
    >
      <path
        id="prefix__facebook_logo"
        d="M16775.5 1512.806a16 16 0 115 0v-11.182h3.73l.709-4.624h-4.439v-3a2.316 2.316 0 012.609-2.5h2.016v-3.937a25.116 25.116 0 00-3.584-.312c-3.781 0-6.039 2.327-6.039 6.225V1497h-4.062v4.624h4.063v11.176z"
        data-name="facebook logo"
        transform="translate(-16761.502 -1480.5)"
      />
    </svg>
  ),
  twitter: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31.885"
      height="25.927"
      viewBox="0 0 31.885 25.927"
    >
      <path
        id="twitter_logo"
        d="M85.116 124.323c12.032 0 18.615-9.978 18.615-18.615 0-.281 0-.561-.013-.842a13.329 13.329 0 0 0 3.266-3.394 13.261 13.261 0 0 1-3.764 1.033 6.582 6.582 0 0 0 2.884-3.624 13.014 13.014 0 0 1-4.159 1.582 6.544 6.544 0 0 0-11.317 4.478 7.232 7.232 0 0 0 .166 1.493A18.578 18.578 0 0 1 77.307 99.6a6.568 6.568 0 0 0 2.029 8.74 6.618 6.618 0 0 1-2.96-.817v.089a6.557 6.557 0 0 0 5.244 6.418 6.581 6.581 0 0 1-2.947.115 6.543 6.543 0 0 0 6.112 4.542 13.123 13.123 0 0 1-8.128 2.807 12.592 12.592 0 0 1-1.557-.094 18.61 18.61 0 0 0 10.016 2.922"
        transform="translate(-75.1 -98.396)"
      />
    </svg>
  ),
  instagram: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30.841"
      height="30.841"
      viewBox="0 0 30.841 30.841"
    >
      <path
        id="prefix__Instagram_logo"
        d="M15599.768 1782.752a10.894 10.894 0 01-3.619-.694 7.284 7.284 0 01-2.646-1.721 7.38 7.38 0 01-1.722-2.642 10.929 10.929 0 01-.689-3.622c-.074-1.592-.09-2.1-.09-6.151s.016-4.559.09-6.151a10.929 10.929 0 01.689-3.622 7.38 7.38 0 011.722-2.642 7.285 7.285 0 012.646-1.721 10.9 10.9 0 013.619-.694c1.592-.074 2.1-.09 6.154-.09s4.56.016 6.151.09a10.911 10.911 0 013.622.694 7.63 7.63 0 014.363 4.363 10.99 10.99 0 01.694 3.622c.07 1.592.09 2.1.09 6.151s-.02 4.559-.09 6.151a10.991 10.991 0 01-.694 3.622 7.629 7.629 0 01-4.363 4.363 10.908 10.908 0 01-3.622.694c-1.592.074-2.1.09-6.151.09s-4.563-.016-6.154-.09zm.125-26.976a8.285 8.285 0 00-2.771.514 4.935 4.935 0 00-2.83 2.83 8.272 8.272 0 00-.514 2.772c-.075 1.572-.091 2.046-.091 6.029s.016 4.457.091 6.029a8.272 8.272 0 00.514 2.772 4.935 4.935 0 002.83 2.83 8.285 8.285 0 002.771.514c1.572.071 2.043.086 6.029.086s4.454-.016 6.029-.086a8.257 8.257 0 002.769-.514 4.956 4.956 0 002.834-2.83 8.272 8.272 0 00.514-2.772c.07-1.572.086-2.046.086-6.029s-.016-4.457-.086-6.029a8.272 8.272 0 00-.514-2.772 4.637 4.637 0 00-1.117-1.713 4.577 4.577 0 00-1.717-1.117 8.257 8.257 0 00-2.769-.514c-1.575-.071-2.046-.086-6.029-.086s-4.457.016-6.029.086zm-1.635 12.145a7.662 7.662 0 117.664 7.66 7.666 7.666 0 01-7.664-7.66zm2.689 0a4.973 4.973 0 104.975-4.975 4.974 4.974 0 00-4.975 4.975zm11.149-7.966a1.79 1.79 0 111.788 1.792 1.787 1.787 0 01-1.784-1.792z"
        data-name="Instagram logo"
        transform="translate(-15590.502 -1752.5)"
      />
    </svg>
  ),
  youtube: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40.691"
      height="28.962"
      viewBox="0 0 40.691 28.962"
    >
      <path
        id="prefix__Youtube_logo"
        d="M15554.3 1779.963c-.124 0-12.449-.01-15.51-.833a4.992 4.992 0 01-3.51-3.533c-.818-3.081-.828-9.55-.828-9.615s.01-6.535.828-9.615a4.992 4.992 0 013.51-3.533c3.061-.823 15.386-.833 15.51-.833s12.445.01 15.5.833a5 5 0 013.511 3.533c.818 3.081.828 9.55.828 9.615s-.01 6.535-.828 9.615a5 5 0 01-3.511 3.533c-3.051.824-15.372.833-15.5.833zm-4.062-19.884v11.8l10.373-5.9-10.372-5.9z"
        data-name="Youtube logo"
        transform="translate(-15533.956 -1751.501)"
      />
    </svg>
  ),
  linkedin: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.056"
      height="26.999"
      viewBox="0 0 27.056 26.999"
    >
      <path
        d="M40.459-2313v-8.772c0-2.092-.041-4.783-2.914-4.783-2.916,0-3.362,2.279-3.362,4.631v8.92h-5.6v-18.029h5.376v2.464h.076a5.892,5.892,0,0,1,5.306-2.923c5.671,0,6.719,3.735,6.719,8.591v9.9Zm-21,0v-18.032h5.607V-2313Zm-.206-22.5a3.25,3.25,0,0,1,.7-3.546,3.251,3.251,0,0,1,3.543-.709,3.25,3.25,0,0,1,2.009,3,3.236,3.236,0,0,1-.945,2.3,3.235,3.235,0,0,1-2.294.952H22.25A3.249,3.249,0,0,1,19.251-2335.5Z"
        transform="translate(-19 2340)"
      />
    </svg>
  ),
};
