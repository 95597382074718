import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

import { Annotation, Container } from '@styles/globalStyles';
import { PAGES } from '@routes';
import BenefitsSection, {
  IntroductionSection,
} from '@components/BenefitsSection';
import Banner, { BANNER_VARIANTS } from '@components/Banner';

const Phev = ({
  pagesStore: {
    phev: { data },
    getPage,
  },
  retailerStore: { dealerNumber, isDealerSet },
}) => {
  useEffect(() => {
    isDealerSet && getPage({ page: PAGES.phev, dealerNumber });
  }, [isDealerSet]);

  const sectionsData = data?.pageSections || [];
  const banners = data?.banners || [];

  return (
    <>
      <Helmet>
        <title>Plug-in Hybrid Benefits | BMW Electrified</title>
      </Helmet>
      <Container>
        <IntroductionSection content={data.description} title={data.headline} />
      </Container>
      {sectionsData.map((section, index) => (
        <BenefitsSection key={`lp-section_${index}`} section={section} />
      ))}
      {banners.map((banner, index) => (
        <Banner
          key={`banner-${index}`}
          {...banner}
          variant={BANNER_VARIANTS.BENEFIT}
        />
      ))}
      {data.annotation && (
        <Container>
          <Annotation dangerouslySetInnerHTML={{ __html: data.annotation }} />
        </Container>
      )}
    </>
  );
};

Phev.propTypes = {
  pagesStore: PropTypes.shape({
    phev: PropTypes.shape({
      data: PropTypes.object,
      isLoading: PropTypes.bool,
    }),
    getPage: PropTypes.func,
  }).isRequired,
  pageStore: PropTypes.object.isRequired,
};

export default inject('pagesStore', 'retailerStore')(observer(Phev));
