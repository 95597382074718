import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper } from '@components/Header/style';
import Navbar from '@components/Header/components/Navbar';
import PageBanners from '@components/Header/components/PageBanners';

const Header = ({ page }) => (
  <Wrapper>
    <Navbar theme={!page ? 'dark' : 'light'} />
    {page && <PageBanners {...{ page }} />}
  </Wrapper>
);

Header.defaultProps = {
  page: undefined,
};

Header.propTypes = {
  page: PropTypes.string,
};

export default Header;
