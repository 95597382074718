import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TabsRow, Tab } from '@components/Tabs/style';

const Tabs = ({ id, tabs, defaultActiveTab, onChange }) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);

  useEffect(() => {
    setActiveTab(defaultActiveTab);
  }, [defaultActiveTab]);

  const onTabClick = (index, tab) => {
    setActiveTab(index);
    if (onChange) {
      onChange(index, tab);
    }
  };

  return (
    <TabsRow>
      {tabs.map((tab, index) => (
        <Tab
          key={`${id}-tab_${index}`}
          onClick={() => onTabClick(index, tab)}
          active={activeTab === index}
        >
          {tab.text}
        </Tab>
      ))}
    </TabsRow>
  );
};

Tabs.defaultProps = {
  defaultActiveTab: 0,
  onChange: undefined,
};

Tabs.propTypes = {
  id: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ).isRequired,
  defaultActiveTab: PropTypes.number,
  onChange: PropTypes.func,
};

export default Tabs;
