import styled from '@emotion/styled/macro';

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 14px;
`;

export const InputBase = styled.label`
  position: relative;
  padding-left: 35px;
  margin-right: 36px;
  cursor: pointer;
  user-select: none;
  display: inline-block;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  & input:not(:checked) ~ ${Label} {
    color: ${({ theme }) => theme.colors.gray};
  }
`;

export const FieldWrapper = styled.div`
  position: relative;
`;
