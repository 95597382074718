const getMobileLabel = (id, fallback) => {
  switch (id) {
    case 'book':
      return 'Test drive';
    case 'locator':
      return 'Find stock';
    case 'financing':
      return 'Finance';
    case 'vehicle':
      return 'Configure';
    default:
      return fallback;
  }
};

const addMobileLabels = (links) =>
  links.map((link) => {
    const parsedLink = { ...link };
    parsedLink.mobileText = getMobileLabel(parsedLink.icon, parsedLink.text);
    return parsedLink;
  });

export default addMobileLabels;
