import styled from '@emotion/styled/macro';
import Section from '@components/Section';
import { css } from '@emotion/react';
import { breakpoint } from '@styles/breakpoints';

export const SectionText = styled(Section)`
  max-width: 784px;
  margin: 0 20px 0 20px;

  h2 {
    font-size: 24px;
  }

  @media ${breakpoint.tabletPortrait} {
    h2 {
      font-size: 35px;
    }
    margin: 0 30px 40px;
  }
`;

const backgroundImage = (props) =>
  props.image
    ? css`
        position: relative;

        &::before {
          content: '';
          position: absolute;
          background-image: url(${props.image[0]});
          background-size: 200%;
          background-repeat: no-repeat;
          background-position: 20% 0%;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          opacity: 0.7;
          z-index: -1;
        }
      `
    : null;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  max-width: ${({ theme }) => theme.banners.maxWidth}px;
  flex-wrap: wrap;
  padding: 20px 0 35px 0;
  ${backgroundImage}
  margin: 0 auto 60px;

  @media ${breakpoint.md} {
    padding-bottom: 80px;
    padding-top: 65px;
    margin-bottom: 120px;
  }
`;

export const VideoWrapper = styled.div`
  shadow-box: 0 8px 5px #00000050;
  width: 100%;
  box-size: border-box;
  box-shadow: 0 10px 8px #0000005c;
  max-width: 990px;
`;
