import React from 'react';
import { Global, css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import reset from 'emotion-reset';
import { Flex, Link } from 'rebass';
import { Link as RouterLink } from 'react-router-dom';

import SwiperCore, {
  EffectFade,
  Pagination,
  Mousewheel,
  Autoplay,
} from 'swiper/core';

import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/effect-fade/effect-fade.min.css';

import theme from '@styles/theme';
import { breakpoint } from '@styles/breakpoints';

SwiperCore.use([EffectFade, Pagination, Mousewheel, Autoplay]);

/* eslint-disable */
/* prettier-ignore */
const GlobalStyle = () => (
  <Global
    styles={css`
      @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap');
      ${reset}

      * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      @font-face {
        font-family: 'BMW-Regular';
        src: url('/fonts/BMWTypeNextTT-Regular.eot'); /* IE9*/
        src: url('/fonts/BMWTypeNextTT-Regular.eot?#iefix')
            format('embedded-opentype'),
          /* IE6-IE8 */ url('/fonts/BMWTypeNext-Regular.woff2') format('woff2'),
          /* chrome、firefox */ url('/fonts/BMWTypeNextTT-Regular.woff')
            format('woff'); /* chrome、firefox */
      }

      @font-face {
        font-family: 'BMW-Bold';
        src: url('/fonts/BMWTypeNextTT-Bold.eot'); /* IE9*/
        src: url('/fonts/BMWTypeNextTT-Bold.eot?#iefix')
            format('embedded-opentype'),
          /* IE6-IE8 */ url('/fonts/BMWTypeNext-Bold.woff2') format('woff2'),
          /* chrome、firefox */ url('/fonts/BMWTypeNextTT-Bold.woff')
            format('woff'); /* chrome、firefox */
      }

      @font-face {
        font-family: 'BMW-Light';
        src: url('/fonts/BMWTypeNextTT-Light.eot'); /* IE9*/
        src: url('/fonts/BMWTypeNextTT-Light.eot?#iefix')
            format('embedded-opentype'),
          /* IE6-IE8 */ url('/fonts/BMWTypeNext-Light.woff2') format('woff2'),
          /* chrome、firefox */ url('/fonts/BMWTypeNextTT-Light.woff')
            format('woff'); /* chrome、firefox */
      }

      @font-face {
        font-family: 'BMW-Thin';
        src: url('/fonts/BMWTypeNextTT-Thin.eot'); /* IE9*/
        src: url('/fonts/BMWTypeNextTT-Thin.eot?#iefix')
            format('embedded-opentype'),
          /* IE6-IE8 */ url('/fonts/BMWTypeNext-Thin.woff2') format('woff2'),
          /* chrome、firefox */ url('/fonts/BMWTypeNextTT-Thin.woff')
            format('woff'); /* chrome、firefox */
      }

      @font-face {
        font-family: 'BMW-Type-Web-Bold';
        src: url('/fonts/bmwtypewebbo_all.eot'); /* IE9*/
        src: url('/fonts/bmwtypewebbo_all.eot?#iefix')
            format('embedded-opentype'),
          /* IE6-IE8 */ url('/fonts/bmwtypewebbo_all') format('svg'),
          /* chrome、firefox */ url('/fonts/bmwtypewebbo_all.woff')
            format('woff'); /* chrome、firefox */
      }

      * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      *,
      *:before,
      *:after {
        box-sizing: border-box;
      }
      html {
        box-sizing: border-box;
      }

      body {
        background-color: #fff;
        font-family: ${theme.fonts.body}, Arial;
        font-size: ${theme.fontSizes[1]}px;
        line-height: 1.4;
        color: ${theme.colors.text};
      }

      a {
        text-decoration: none;
        font-family: ${theme.fonts.bold};
        color: ${theme.colors.primary};

        &:hover {
          color: #0653b6;
          footer & { color: #262626; }
        }
      }

      sub {
        vertical-align: sub; 
        font-size: 60%;
      }

      sup {
        vertical-align: super; 
        font-size: 60%;
      }
      
      strong {
        font-weight: 600;
      }
    `}
  />
);

export default GlobalStyle;

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.layoutMaxWidth};
  padding: 0 20px;

  @media ${breakpoint.tabletPortrait} {
    padding: 0 45px;
  }

  @media ${breakpoint.md} {
    padding: 0 30px;
  }
`;

export const ContainerFullMobile = styled(Container)`
  padding: 0;

  @media ${breakpoint.md} {
    padding: 0 30px;
  }
`;

export const LineSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: #bbbbbb;
  margin: 20px 0;

  @media ${breakpoint.md} {
    margin: ${(props) => props.margin || '20px 0 70px'};
  }
`;

export const Button = styled.button`
  ${({ theme, variant }) => theme.buttons[variant]}
  display: inline-block;
  align-items: center;
  width: 100%;
  height: 50px;
  border: none;

  img {
    margin-right: 15px;
  }

  @media ${breakpoint.tabletPortrait} {
    max-width: 285px;
  }

  &:hover {
    color: white;
  }

  ${(props) =>
    props.type === 'backlink' &&
    css`
      margin: 30px 0 0 10px;
    `}
`;

export const LinkButton = (props) => (
  <Button as={props.to ? RouterLink : Link} {...props} />
);

export const HEADING = (theme) => css`
  font-family: ${theme.fonts.heading};
  font-weight: ${theme.fontWeights.thin};
  line-height: 1;
`;

export const FlexRow = styled(Flex)`
  flex-direction: column;

  ${({ bp = breakpoint.tabletPortrait }) => `
    @media ${bp} {
      flex-direction: row;
    }
  `}
`;

export const InfoIcon = styled(
  React.forwardRef((props, ref) => (
    <span ref={ref} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1.757"
        height="8.784"
        viewBox="0 0 1.757 8.784"
      >
        <path
          d="M9,14.034h1.757V8.669H9ZM9,7.007h1.757V5.25H9Z"
          transform="translate(-9.004 -5.25)"
        />
      </svg>
    </span>
  )),
)`
  border-radius: 100%;
  width: 18px;
  height: 18px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.lightGray};
  padding: 0;
  display: inline-flex;
  align-items: center;

  svg {
    width: 100%;
    path {
      fill: #ffffff;
    }
  }
`;

export const RESET_BUTTON = () => css`
  background: none;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
`;

export const Introduction = styled.div`
  font-size: 16px;
  max-width: 1072px;
  margin: 46px 0 50px;

  sup {
    font-size: 12px;
    vertical-align: super;
  }

  @media ${breakpoint.tabletPortrait} {
    font-size: 20px;
    margin: 110px 0 50px;
  }

  @media ${breakpoint.md} {
    margin: 58px 0 0;
  }
`;

export const Annotation = styled.div`
  font-size: ${theme.fontSizes[0]}px;
  padding-top: 20px;
  border-top: 1px solid #e6e6e6;
  margin-top: 80px;
`;
