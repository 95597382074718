import React, { useState, useEffect } from 'react';
import { func, number, oneOfType, string } from 'prop-types';
import { Label, FieldWrapper } from '@components/Form/style';
import {
  StepperWrapper,
  StepperLabelsWrapper,
  StepperLabel,
  StepPopUp,
  DotWrapper,
  Dot,
  ArrowUp,
  DashWrapper,
  Dash,
  RestDash,
} from '@components/Form/Stepper/style';

const Stepper = ({
  id,
  label,
  min,
  max,
  steps,
  value,
  onChange,
  formatLabel,
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const step = Math.abs(max - min) / steps;

    setOptions(
      new Array(steps + 1).fill(null).map((_, index) => {
        const optionValue = index * step + min;
        return {
          value: optionValue,
          label: formatLabel ? formatLabel(optionValue) : `${optionValue}`,
        };
      }),
    );
  }, [min, max, steps]);

  return (
    <FieldWrapper>
      {label && (
        <Label as="label" htmlFor={id}>
          {label}
        </Label>
      )}
      <StepperWrapper>
        {options.map(({ value: optionValue, label: optionLabel }, index) => (
          <DotWrapper
            key={optionValue}
            onClick={(e) => onChange(optionValue, e)}
          >
            <Dot
              isFirst={index === 0}
              active={optionValue === value}
              onClick={(e) => onChange(optionValue, e)}
            />
            <StepPopUp>
              <ArrowUp />
              {optionLabel}
            </StepPopUp>
          </DotWrapper>
        ))}
        <DashWrapper>
          <Dash percentage={((value - min) * 100) / (max - min)} />
          <RestDash percentage={100} />
        </DashWrapper>
      </StepperWrapper>
      <StepperLabelsWrapper>
        <StepperLabel>{min}</StepperLabel>
        <StepperLabel right>{max}</StepperLabel>
      </StepperLabelsWrapper>
    </FieldWrapper>
  );
};

Stepper.defaultProps = {
  formatLabel: undefined,
};

Stepper.propTypes = {
  min: number.isRequired,
  max: number.isRequired,
  steps: number.isRequired,
  formatLabel: func,
  id: string.isRequired,
  label: string.isRequired,
  value: oneOfType([string, number]).isRequired,
  onChange: func.isRequired,
};

export default Stepper;
