import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes } from '@app/api';
import useRuntimeEnvironmentVariables from '@hooks/useRuntimeEnvironmentVariables';
import addMobileLabels from '@utils/addMobileLabels';

export class CommonsStore {
  constructor() {
    makeAutoObservable(this);
  }

  isLoading = false;

  error = null;

  banners = {};

  footer = {
    socialMedia: [],
    contact: [],
    legal: [],
  };

  links = [];

  retailer = {
    address: '',
    dealerNumber: '',
    name: '',
    postcode: '',
  };

  isIrelandSite =
    useRuntimeEnvironmentVariables().REACT_APP_IRE_VERSION === 'true';

  locale = this.isIrelandSite ? 'en-IE' : 'en-GB';

  currency = this.isIrelandSite ? 'EUR' : 'GBP';

  currencySymbol = this.isIrelandSite ? '€' : '£';

  currencyHundredthSubdivisionSymbol = this.isIrelandSite ? 'c' : 'p';

  distanceUnitsLong = this.isIrelandSite ? 'kilometres' : 'miles';

  distanceUnitsShort = this.isIrelandSite ? 'km' : 'mi';

  distanceCoverageUnits = this.isIrelandSite ? 'kilometrage' : 'mileage';

  fuelEconomyUnits = this.isIrelandSite ? 'l/100 km' : 'mpg';

  mapDataIsReady = false;

  getCommons = async ({ dealerNumber, onError }) => {
    try {
      this.isLoading = true;

      const {
        data: { banners, footer, links, retailer },
      } = await API(APIRoutes.COMMONS({ dealerNumber }));

      runInAction(() => {
        this.banners = banners;
        this.footer = footer;
        this.links = addMobileLabels(links);
        this.retailer = retailer;
      });

      this.mapDataIsReady = true;
    } catch (error) {
      runInAction(() => {
        this.error = error;
        onError && onError(error);
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  asCurrency = Intl.NumberFormat(this.locale, {
    style: 'currency',
    currency: this.currency,
  }).format;
}

export default new CommonsStore();
