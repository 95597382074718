import React from 'react';
import PropTypes from 'prop-types';
import { Label, FieldWrapper } from '@components/Form/style';

import {
  InputWrapper,
  Input,
  InputIndicatorWrapper,
} from '@components/Form/TextField/style';

const TextField = ({
  id,
  label,
  inputIndicator: InputIndicator,
  value,
  onChange,
  ...inputProps
}) => (
  <FieldWrapper>
    {label && (
      <Label as="label" htmlFor={id}>
        {label}
      </Label>
    )}
    <InputWrapper>
      <Input
        {...{ id, value, onChange, ...inputProps }}
        withIndicator={!!InputIndicator}
      />
      {InputIndicator ? (
        <InputIndicatorWrapper withValue={!!value}>
          <InputIndicator />
        </InputIndicatorWrapper>
      ) : null}
    </InputWrapper>
  </FieldWrapper>
);

TextField.defaultProps = {
  label: undefined,
  inputIndicator: undefined,
};

TextField.propTypes = {
  label: PropTypes.node,
  inputIndicator: PropTypes.elementType,
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TextField;
