import React from 'react';
import PropTypes from 'prop-types';

import {
  AccordionItemWrap,
  AccordionItemHead,
  AccordionItemBody,
  AccordionItemBodyInner,
} from '@components/AccordionItem/style';

class AccordionItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      height: 0,
      style: {
        height: `0px`,
      },
    };
    this.body = React.createRef();
    this.toggle = this.toggle.bind(this);
  }

  show() {
    this.body.current.style.height = '';
  }

  hide() {
    this.body.current.style.height = '0';
  }

  toggle() {
    const { state, body } = this;
    let { open, height } = state;

    open = !open;

    if (height === 0) {
      this.show();
      height = body.current.clientHeight;
      this.hide();

      this.setState(() => ({
        height,
      }));
    }

    this.setState(() => ({
      open,
    }));

    setTimeout(() => {
      body.current.style.height = `${open ? height : 0}px`;
    }, 50);
  }

  render() {
    const { state, props, toggle, body } = this;
    const { title, content } = props;
    const { style, open } = state;
    return (
      <AccordionItemWrap data-open={open}>
        <AccordionItemHead onClick={toggle}>{title}</AccordionItemHead>
        <AccordionItemBody ref={body} style={style}>
          <AccordionItemBodyInner
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </AccordionItemBody>
      </AccordionItemWrap>
    );
  }
}

AccordionItem.defaultProps = {
  title: 'HEAD',
  content: 'BODY',
};

AccordionItem.propTypes = {
  title: PropTypes.any,
  content: PropTypes.string,
};

export default AccordionItem;
