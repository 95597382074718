import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route as RouteRaw } from 'react-router-dom';
import Layout from '@components/Layout';

const Route = ({ children, page, routeUpdate, noLayout, ...rest }) => {
  useEffect(() => {
    // scroll to top on route change
    window.scrollTo(0, 0);

    if (routeUpdate) {
      routeUpdate(rest);
    }
  }, [rest?.location?.pathname]);

  return (
    <RouteRaw
      {...rest}
      render={() =>
        noLayout ? children : <Layout {...{ page }}>{children}</Layout>
      }
    />
  );
};

Route.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Route;
