import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { inject, observer } from 'mobx-react';

import ICONS from '@components/IconLinks/icons';
import {
  Link,
  Icon,
  Text,
  IconLinksRow,
  MobileText,
} from '@components/IconLinks/style';

import Sticky from '@components/Sticky';
import { Container } from '@styles/globalStyles';

const IconLinks = ({ commonsStore: { links } }) => (
  <Sticky>
    <Container>
      <IconLinksRow>
        {links.map(({ text, mobileText, url, currentUrl, target, icon }) => (
          <Link key={`icon-${icon}`} href={currentUrl || url} target={target}>
            <Icon>{ICONS[icon]}</Icon>
            <Text>{text}</Text>
            <MobileText>{mobileText}</MobileText>
          </Link>
        ))}
      </IconLinksRow>
    </Container>
  </Sticky>
);

IconLinks.propTypes = {
  commonsStore: shape({
    links: arrayOf(
      shape({
        icon: string,
        target: string,
        text: string,
        url: string,
      }),
    ),
  }).isRequired,
};

export default inject('commonsStore')(observer(IconLinks));
