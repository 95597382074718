import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import { CSSTransition } from 'react-transition-group';
import { Waypoint } from 'react-waypoint';

import {
  Wrapper,
  DetailsContainer,
  Background,
  CarWrapper,
  Car,
  TextWrapper,
  DetailsWrapper,
  Type,
  Model,
  Price,
  ToggleButton,
  OverviewTitle,
  OverviewWrapper,
  Description,
  Row,
  MainRow,
  ValuesRow,
  ValueItem,
  DetailsColumn,
  DetailsItem,
  OverviewTransitions,
  Info,
  InfoTooltipBody,
  BrochureRequestButton,
} from '@containers/Home/components/CarDetails/style';
import InfoTooltip from '@components/Tooltip/components/InfoTooltip';
import useMatchBreakpoint from '@root/hooks/useMatchBreakpoint';
import { breakpoint } from '@styles/breakpoints';
import { KINDS } from '@root/stores/carsStore';

import InfoIcon from '@assets/images/icons/info-icon.svg';

import { LinkButton } from '@styles/globalStyles';

import ReactGA from 'react-ga4';

const CarDetails = ({
  carsStore: { activeCar, carKinds, activeKind },
  commonsStore: {
    asCurrency,
    distanceUnitsLong,
    distanceUnitsShort,
    fuelEconomyUnits,
  },
  tooltipContent,
  commonsStore: { isIrelandSite },
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const overviewRef = useRef(null);

  const {
    gradientStart,
    gradientEnd,
    fullName,
    description,
    price,
    emissionsCombined,
    powerOutput,
    combinedWltp,
    transmission,
    driveType,
    performance,
    benefitInKind,
    energyConsumptionWltp,
    electricRangeWltp,
    hoverImage,
    hoverImageMobile,
    brochureLink,
  } = activeCar;

  const isDesktop = useMatchBreakpoint(breakpoint.md);
  const isTablet = useMatchBreakpoint(breakpoint.tabletPortrait);

  const kind = carKinds[activeKind]?.text;

  const carImage = (
    <Car src={isDesktop ? hoverImage : hoverImageMobile} alt="" />
  );

  const tooltipProps = {
    ...(isTablet
      ? {
          placement: 'right',
          offset: [0, 15],
        }
      : {
          placement: 'bottom',
          offset: [0, -20],
        }),
  };

  const onOverviewEnter = () => {
    if (overviewRef.current) {
      overviewRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Waypoint onEnter={() => setIsVisible(true)} topOffset={100}>
      <Wrapper>
        <CSSTransition
          in={isVisible}
          classNames="details-transition"
          mountOnEnter
          timeout={{
            enter: 2000,
            exit: 2000,
          }}
        >
          <DetailsContainer>
            <Background
              gradientStart={gradientStart}
              gradientEnd={gradientEnd}
            />
            <MainRow>
              <DetailsWrapper>
                <TextWrapper>
                  <Type>{kind}</Type>
                  <Model>{fullName}.</Model>
                  <Price>
                    From {asCurrency(price)}
                    <InfoTooltip
                      {...tooltipProps}
                      body={
                        <InfoTooltipBody
                          dangerouslySetInnerHTML={{
                            __html: tooltipContent,
                          }}
                        />
                      }
                    >
                      <Info src={InfoIcon} alt="" />
                    </InfoTooltip>
                  </Price>
                </TextWrapper>
                {!isDesktop && carImage}
                <ValuesRow>
                  {activeKind === KINDS.BEV ? (
                    <>
                      <ValueItem>
                        <span>{powerOutput}</span>
                        <span>Power output</span>
                      </ValueItem>
                      <ValueItem>
                        <span>
                          {electricRangeWltp} {distanceUnitsLong}
                        </span>
                        <span>Electric range WLTP</span>
                      </ValueItem>
                      <ValueItem>
                        <span>
                          {energyConsumptionWltp} {distanceUnitsShort}/kWh
                        </span>{' '}
                        <span>Energy consumption WLTP</span>
                      </ValueItem>
                    </>
                  ) : (
                    <>
                      <ValueItem>
                        <span>
                          {combinedWltp} {fuelEconomyUnits}
                        </span>
                        <span>Combined WLTP</span>
                      </ValueItem>
                      <ValueItem>
                        <span>
                          {electricRangeWltp} {distanceUnitsLong}
                        </span>
                        <span>Electric range WLTP</span>
                      </ValueItem>
                      <ValueItem>
                        <span>
                          {emissionsCombined} g/km CO<sub>2</sub>
                        </span>{' '}
                        <span>Emissions combined</span>
                      </ValueItem>
                    </>
                  )}
                </ValuesRow>
                <ToggleButton
                  onClick={() => setIsOpen(!isOpen)}
                  isOpen={isOpen}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="16"
                    viewBox="0 0 10 16"
                  >
                    <path d="M0,14,6,8,0,2,2,0l8,8L2,16Z" />
                  </svg>
                  {isOpen ? 'Close overview' : 'Discover more'}
                </ToggleButton>
              </DetailsWrapper>
              {isDesktop && <CarWrapper>{carImage}</CarWrapper>}
            </MainRow>
            <OverviewTransitions />
            <CSSTransition
              in={isOpen}
              classNames="overview-transition"
              unmountOnExit
              onEnter={onOverviewEnter}
              timeout={{
                enter: 1000,
                exit: 1000,
              }}
            >
              <OverviewWrapper ref={overviewRef}>
                <OverviewTitle>Model overview</OverviewTitle>
                <Description>{description}</Description>
                <Row>
                  <DetailsColumn>
                    <DetailsItem>
                      <b>Transmission</b>
                      <span>{transmission}</span>
                    </DetailsItem>
                    <DetailsItem>
                      <b>Drive type</b>
                      <span>{driveType}</span>
                    </DetailsItem>
                  </DetailsColumn>
                  <DetailsColumn>
                    <DetailsItem>
                      <b>Performance</b>
                      <span>{performance}</span>
                    </DetailsItem>
                    {isIrelandSite ? null : (
                      <DetailsItem>
                        <b>Benefit in kind</b>
                        <span>{benefitInKind}</span>
                      </DetailsItem>
                    )}
                  </DetailsColumn>
                </Row>
                {brochureLink && (
                  <BrochureRequestButton>
                    <LinkButton
                      variant="primary"
                      target="_blank"
                      rel="noreferrer"
                      href={brochureLink}
                      onClick={() =>
                        ReactGA.event({
                          category: 'Brochure request link-button',
                          action: 'BrochureRequest',
                          label: 'Link-button in the model overview section',
                          transport: 'beacon',
                        })
                      }
                    >
                      Request a digital brochure
                    </LinkButton>
                  </BrochureRequestButton>
                )}
              </OverviewWrapper>
            </CSSTransition>
          </DetailsContainer>
        </CSSTransition>
      </Wrapper>
    </Waypoint>
  );
};

CarDetails.propTypes = {
  carsStore: PropTypes.shape({
    activeCar: PropTypes.object,
    carKinds: PropTypes.object,
    activeKind: PropTypes.string,
  }).isRequired,
  commonsStore: PropTypes.shape({
    asCurrency: PropTypes.func,
    distanceUnitsLong: PropTypes.string,
    distanceUnitsShort: PropTypes.string,
    fuelEconomyUnits: PropTypes.string,
  }).isRequired,
};

export default inject('carsStore', 'commonsStore')(observer(CarDetails));
