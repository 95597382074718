import React, { useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import {
  TooltipContainer,
  TooltipArrow,
  TooltipBody,
  TooltipTransitions,
} from '@components/Tooltip/style';

export * from '@components/Tooltip/style';

export const PLACEMENT = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
};

export const TRIGGER = {
  CLICK: 'click',
  HOVER: 'hover',
  NONE: null,
};

const Tooltip = ({
  children,
  tooltipBody,
  trigger,
  isActive,
  placement,
  hookArguments,
  minWidth,
  offset,
  show,
}) => {
  const [controlledVisible, setControlledVisible] = useState(false);

  const isOpen = controlledVisible || show;

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef } =
    usePopperTooltip({
      trigger: isActive ? trigger : null,
      interactive: trigger === TRIGGER.CLICK,
      placement,
      offset,
      delayHide: trigger === TRIGGER.CLICK ? 200 : 50,
      closeOnTriggerHidden: true,
      visible: isOpen,
      onVisibleChange: setControlledVisible,
      ...hookArguments,
    });

  return (
    <>
      <TooltipTransitions />
      {React.cloneElement(children, { ref: setTriggerRef })}
      <CSSTransition
        in={isOpen}
        classNames="tooltip-transition"
        unmountOnExit
        timeout={{
          enter: 0,
          exit: 200,
        }}
      >
        <TooltipContainer ref={setTooltipRef} {...getTooltipProps()}>
          <TooltipBody minWidth={minWidth}>{tooltipBody}</TooltipBody>
          <TooltipArrow {...getArrowProps()} />
        </TooltipContainer>
      </CSSTransition>
    </>
  );
};

Tooltip.defaultProps = {
  placement: PLACEMENT.TOP,
  trigger: TRIGGER.HOVER,
  hookArguments: {},
  isActive: true,
  minWidth: '130px',
  offset: [0, 12],
};

Tooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  tooltipBody: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  isActive: PropTypes.bool,
  trigger: PropTypes.oneOf(Object.values(TRIGGER)),
  placement: PropTypes.oneOf(Object.values(PLACEMENT)),
  hookArguments: PropTypes.object,
  minWidth: PropTypes.string,
  offset: PropTypes.arrayOf(PropTypes.number),
};

export default Tooltip;
