import React from 'react';
import { inject, observer } from 'mobx-react';

import { Container } from '@styles/globalStyles';
import { ContentWrapper } from '@containers/DataPage/style';

const CookiePage = () => (
  <Container>
    <ContentWrapper>
      <div className="epaas-policy-page-container" />
    </ContentWrapper>
  </Container>
);

export default inject('pagesStore')(observer(CookiePage));
