import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const IconLinksRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 5px;
  transition: all 0.3s;
  margin: 0 auto;
  width: 100%;

  @media ${breakpoint.md} {
    grid-gap: 20px;
  }

  [data-sticky='true'] & {
    @media ${breakpoint.md} {
      width: 725px;
    }
  }
`;

export const Icon = styled.span`
  margin-bottom: 5px;

  svg {
    height: 20px;
    vertical-align: middle;

    @media ${breakpoint.md} {
      height: 25px;
    }
  }

  @media ${breakpoint.md} {
    margin-bottom: 10px;
  }
`;

export const Text = styled.span`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 13px;
  color: ${({ theme }) => theme.colors.gray};
  flex: 1;
  text-align: center;

  @media ${breakpoint.mobileOnly} {
    display: none;
  }
`;

export const MobileText = styled.span`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 10px;
  color: ${({ theme }) => theme.colors.gray};
  flex: 1;
  text-align: center;

  @media ${breakpoint.smAndBeyond} {
    font-size: 13px;
  }

  @media ${breakpoint.md} {
    display: none;
  }
`;

export const Link = styled.a`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s;

  [data-sticky='true'] & {
    padding: 10px 0;
    background-color: #fff;

    @media ${breakpoint.md} {
      padding: 10px;
    }

    svg {
      opacity: 0.5;
    }

    &:hover {
      svg {
        opacity: 1;
      }
    }
  }

  [data-sticky='false'] & {
    padding: 10px 8px;
    background-color: #e6e6e6;

    @media ${breakpoint.md} {
      padding: 30px;
    }
  }

  svg {
    transition: all 0.3s;
  }
`;
