import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { HEADING } from '@styles/globalStyles';
import { breakpoint } from '@styles/breakpoints';

export const Header = styled.h2`
  ${({ theme }) => HEADING(theme)};
  font-size: ${({ theme }) => theme.fontSizes[4]}px;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 10px;

  @media ${breakpoint.tabletPortrait} {
    font-size: ${({ theme }) => theme.fontSizes[5]}px;
    margin: 0 0 4px;
  }

  @media ${breakpoint.md} {
  }
`;

export const BannerText = styled.div`
  flex: 1;
`;

export const BannerContent = styled.div`
  order: 2;
  width: 100%;

  p {
    font-size: 15px;

    & + p {
      margin-top: 15px;
    }

    sup {
      font-size: 12px;
      vertical-align: super;
    }
  }

  svg {
    margin-top: 25px;
  }

  @media ${breakpoint.tabletPortrait} {
    width: 100%;
    max-width: none;
  }

  @media ${breakpoint.md} {
    order: 1;
    padding: 45px 90px;
    width: 50%;
  }
`;

export const BannerImage = styled.div`
  width: 100%;
  order: 1;

  img {
    width: 100%;
    object-fit: cover;
  }

  @media ${breakpoint.md} {
    order: 2;
    width: 50%;

    img {
      min-height: 430px;
      object-position: left;
    }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  max-width: ${({ theme }) => theme.banners.maxWidth}px;
  margin: 60px auto;
  flex-wrap: wrap;
  padding: 0 20px;

  @media ${breakpoint.tabletPortrait} {
    padding: 0 45px;
    margin: 70px auto;
  }

  @media ${breakpoint.md} {
    padding: 0;
    margin: 80px auto;
    justify-content: flex-end;
    flex-wrap: nowrap;
    flex-direction: row;
  }

  ${({ fullWidth, charging, theme }) =>
    fullWidth &&
    css`
      ${charging &&
      css`
        @media ${breakpoint.tabletPortrait} {
          padding: 0;
          flex-direction: row;
        }
      `}

      ${BannerImage} {
        @media ${charging ? breakpoint.tabletPortrait : breakpoint.md} {
          width: 100%;
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          z-index: -1;

          img {
            height: 100%;
            min-height: unset;
            object-position: unset;
          }
        }
      }

      ${BannerContent} {
        ${charging &&
        css`
          @media ${breakpoint.tabletPortrait} {
            order: 1;
            padding: 45px 40px;
            width: 50%;
          }

          @media ${breakpoint.md} {
            padding: 90px 90px 90px 130px;
          }
        `}
      }

      ${Header} {
        font-size: ${theme.fontSizes[4]}px;

        @media ${breakpoint.md} {
          font-size: ${theme.fontSizes[5]}px;
        }
      }
    `}

  ${({ benefit, theme }) =>
    benefit &&
    css`
      @media ${breakpoint.tabletPortrait} {
        padding: 0 20px;
      }

      @media ${breakpoint.md} {
        justify-content: flex-start;
        min-height: 450px;
        color: #ffffff;
      }

      ${Header} {
        @media ${breakpoint.tabletPortrait} {
          font-size: ${theme.fontSizes[5]}px;
        }
      }

      ${BannerImage} {
        margin-bottom: 20px;
        img {
          margin: 0 -20px;
          width: 100vw;
        }

        @media ${breakpoint.md} {
          margin-bottom: 0;
          img {
            margin: auto;
            width: 100%;
          }
        }
      }

      ${BannerContent} {
        box-sizing: content-box;

        p a {
          color: ${theme.colors.primary} !important;
          @media ${breakpoint.md} {
            color: white !important;
          }
        }

        @media ${breakpoint.tabletPortrait} {
          max-width: 600px;
          padding: 0 25px;
          align-self: start;
        }

        @media ${breakpoint.md} {
          padding: 90px 50px;
        }

        @media ${breakpoint.lg} {
          max-width: 420px;
          padding: 90px;
        }
      }
    `}
`;

export const BannersRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: -10px 0;

  @media ${breakpoint.tabletPortrait} {
    flex-direction: row;
    margin: 80px -8px;
  }

  @media ${breakpoint.md} {
    margin: 100px auto;
  }
`;

export const Column = styled.div`
  border: 1px solid #d7d7d7;
  margin: 10px 0;

  ${Header} {
    margin: 10px 0 8px;
    font-size: ${({ theme }) => theme.fontSizes[2]}px;

    @media ${breakpoint.md} {
      font-size: ${({ theme }) => theme.fontSizes[4]}px;
    }
  }

  @media ${breakpoint.tabletPortrait} {
    width: 50%;
    max-width: 484px;
    margin: 0 8px;
    display: flex;
    flex-direction: column;
  }
`;

export const ColumnImage = styled.div`
  img {
    width: 100%;
    height: 278px;
    object-fit: cover;
    object-position: left;

    @media ${breakpoint.tabletPortrait} {
      height: 190px;
    }

    @media ${breakpoint.md} {
      height: 278px;
    }
  }
`;

export const ColumnSection = styled.div`
  padding: 20px;
  border-top: none;
  display: flex;
  flex-direction: column;
  flex: 1;

  svg {
    margin-top: 25px;
  }

  @media ${breakpoint.md} {
    height: auto;
    padding: 32px;
  }
`;
