/* eslint-disable max-len */
import React from 'react';

export default {
  vehicle: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.306"
      height="22"
      viewBox="0 0 29.306 22"
    >
      <path
        d="M31.682,16.988V17.8a2.92,2.92,0,0,1-.5,1.718,1.763,1.763,0,0,1-1.551.716H26v-.811a2.444,2.444,0,0,1,2.434-2.434Zm-9.354,3.245H19.894a1.75,1.75,0,0,1-.788-.239q-.453-.239-.453-.573V17.8q0-.334.453-.573a1.75,1.75,0,0,1,.788-.239h3.723a1.546,1.546,0,0,1,.764.215.66.66,0,0,1,.382.6,2.106,2.106,0,0,1-.836,1.837A2.777,2.777,0,0,1,22.328,20.233ZM9.682,12.12a17.5,17.5,0,0,1,1.7-3.412,3.224,3.224,0,0,1,2.887-1.5h8.78a3.224,3.224,0,0,1,2.887,1.5,17.5,17.5,0,0,1,1.7,3.412Zm8.972,7.3q0,.334-.453.573a1.75,1.75,0,0,1-.788.239H14.979a2.777,2.777,0,0,1-1.6-.6,2.106,2.106,0,0,1-.835-1.837.66.66,0,0,1,.382-.6,1.546,1.546,0,0,1,.764-.215h3.723a1.75,1.75,0,0,1,.788.239q.453.239.453.573Zm-7.349.811H7.677a3.1,3.1,0,0,1-1.957-.716A2.13,2.13,0,0,1,4.813,17.8v-.812H8.869A2.444,2.444,0,0,1,11.3,19.422ZM32.493,12.55a1.137,1.137,0,0,0,.668-.477A1.476,1.476,0,0,0,33.3,11.4a1.012,1.012,0,0,0-.239-.668.88.88,0,0,0-.716-.287h-3.1A21.03,21.03,0,0,0,26.409,6.8a5.354,5.354,0,0,0-3.842-1.217H14.74A5.354,5.354,0,0,0,10.9,6.8,21.03,21.03,0,0,0,8.059,10.45h-3.1a.88.88,0,0,0-.716.287A1.012,1.012,0,0,0,4,11.4a1.476,1.476,0,0,0,.143.668,1.137,1.137,0,0,0,.668.477l2.291.477L4,15.365V25.96a1.629,1.629,0,0,0,1.622,1.622H8.056A1.629,1.629,0,0,0,9.682,25.96v-.811H27.625v.811a1.629,1.629,0,0,0,1.622,1.622h2.434A1.629,1.629,0,0,0,33.3,25.96V15.365l-3.1-2.338Z"
        transform="translate(-3.999 -5.582)"
        fill="#4d4d4d"
      />
    </svg>
  ),
  locator: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.651"
      height="25"
      viewBox="0 0 32.651 25"
    >
      <path
        d="M20.567,21.413a6.593,6.593,0,0,0,4.806-1.953,6.4,6.4,0,0,0,1.98-4.738,6.426,6.426,0,0,0-1.982-4.712,6.552,6.552,0,0,0-4.806-1.978,6.552,6.552,0,0,0-4.806,1.978,6.426,6.426,0,0,0-1.982,4.712,6.4,6.4,0,0,0,1.982,4.736,6.593,6.593,0,0,0,4.808,1.956Zm12.584,8.835-7.58-7.47a9.255,9.255,0,0,1-2.352,1.05,9.787,9.787,0,0,1-6.367-.366,9.413,9.413,0,0,1-3.1-15.43,10.422,10.422,0,0,1,3.1-2.05,9.452,9.452,0,0,1,3.716-.733,9.817,9.817,0,0,1,3.765.733,10.615,10.615,0,0,1,3.047,2.051,9.39,9.39,0,0,1,2.8,6.689,9.086,9.086,0,0,1-.372,2.612A9.849,9.849,0,0,1,28.742,19.7l7.581,7.421ZM9.072,21.02a1.7,1.7,0,0,0-.545-1.294,1.818,1.818,0,0,0-1.288-.513H4.266V19.8a1.59,1.59,0,0,0,.667,1.266,2.222,2.222,0,0,0,1.412.537H9.07Zm9.166,4.1H7.834v.586a1.122,1.122,0,0,1-.347.83,1.155,1.155,0,0,1-.843.342H4.861a1.155,1.155,0,0,1-.843-.342,1.122,1.122,0,0,1-.347-.83V18.042l2.279-1.709-1.684-.342a.676.676,0,0,1-.5-.317,1.237,1.237,0,0,1-.1-.513.737.737,0,0,1,.173-.467.623.623,0,0,1,.521-.22H6.645A21.934,21.934,0,0,1,8.4,12.156a3.533,3.533,0,0,1,2.007-1.1l-.2.684-.2.684a2.979,2.979,0,0,0-1.288,1.294A14.664,14.664,0,0,0,7.83,15.7H9.812a8.265,8.265,0,0,0,.3,1.806,10.688,10.688,0,0,0,.647,1.71.984.984,0,0,0-.521.171.491.491,0,0,0-.273.415,1.575,1.575,0,0,0,.619,1.342,1.98,1.98,0,0,0,1.164.467h.545a10.812,10.812,0,0,0,2.651,2.222A10.931,10.931,0,0,0,18.238,25.121Zm6.986-.781v1.367a1.122,1.122,0,0,1-.347.83,1.155,1.155,0,0,1-.843.342H22.252a1.24,1.24,0,0,1-.866-.342,1.086,1.086,0,0,1-.372-.83v-.342a10.766,10.766,0,0,0,2.156-.317A12.752,12.752,0,0,0,25.224,24.34Z"
        transform="translate(-3.672 -5.248)"
        fill="#4d4d4d"
      />
    </svg>
  ),
  book: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <path
        id="Path_263"
        d="M18.358,26.667V24.24A5.1,5.1,0,0,1,20.213,20.1a6.777,6.777,0,0,1,4.464-1.534h1.832a8.687,8.687,0,0,1-2.45,5.243A9.589,9.589,0,0,1,18.358,26.667Zm-10.531-8.1H9.659a5.444,5.444,0,0,1,4.1,1.534,5.7,5.7,0,0,1,1.488,4.144v2.427a8.89,8.89,0,0,1-5.037-2.862A8.858,8.858,0,0,1,7.827,18.563ZM17.168,8.032a8.951,8.951,0,0,1,5.746,1.992,9.458,9.458,0,0,1,3.32,5.014,11.408,11.408,0,0,1-4.625-1.282,8.775,8.775,0,0,0-4.441-1.007,8.775,8.775,0,0,0-4.441,1.007A11.408,11.408,0,0,1,8.1,15.037a9.458,9.458,0,0,1,3.32-5.014A8.951,8.951,0,0,1,17.168,8.032Zm0-3.114a12.406,12.406,0,0,0-4.853.962A12.777,12.777,0,0,0,5.63,12.564a12.73,12.73,0,0,0,0,9.707,12.777,12.777,0,0,0,6.685,6.685,12.73,12.73,0,0,0,9.707,0,12.777,12.777,0,0,0,6.685-6.685,12.73,12.73,0,0,0,0-9.707A12.777,12.777,0,0,0,22.021,5.88,12.406,12.406,0,0,0,17.168,4.918Z"
        transform="translate(-4.668 -4.918)"
        fill="#4d4d4d"
      />
    </svg>
  ),
  financing: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <path
        d="M21.7,12.217a1.953,1.953,0,0,0,1.487.646,2.091,2.091,0,0,0,1.53-.646,2.02,2.02,0,0,0,.6-1.487A2.064,2.064,0,0,0,23.189,8.6a2.02,2.02,0,0,0-1.487.6,2.091,2.091,0,0,0-.646,1.53A1.953,1.953,0,0,0,21.7,12.217ZM17,5.365a2.77,2.77,0,0,1,.926-.582,2.38,2.38,0,0,1,1.1-.194l8.791.3A1.347,1.347,0,0,1,29.028,6.1l.3,8.791a2.387,2.387,0,0,1-.194,1.077,2.738,2.738,0,0,1-.582.948L16.186,29.238a1.145,1.145,0,0,1-1.637,0L4.681,19.37a1.145,1.145,0,0,1,0-1.637Z"
        transform="translate(-4.336 -4.583)"
        fill="#4d4d4d"
      />
    </svg>
  ),
};
