/* eslint-disable no-console */
import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes } from '@app/api';
import isNotEmptyString from '@utils/isNotEmptyString';
import toSlug from '@utils/toSlug';
import toSlugMap from '@utils/toSlugMap';

// eslint-disable-next-line import/no-cycle
import calculatorStore from '@stores/calculatorStore';
// eslint-disable-next-line import/no-cycle
import { history } from '@app/history';
import commonsStore from './commonsStore';

export const CARS_NAV_HEIGHT = 115;

export const KINDS = {
  BEV: 'bev',
  PHEV: 'phev',
};

const ALL_GROUPS = {
  text: 'All',
  id: 'all',
};

const REDIRECTION_DELAY = 800;

export class CarsStore {
  constructor() {
    makeAutoObservable(this);
  }

  carKinds = {
    bev: {
      text: 'All-electric',
      groups: [],
      vehicles: [],
    },
    phev: {
      text: 'Plug-in Hybrid',
      groups: [],
      vehicles: [],
    },
  };

  equivalents = {};

  mapped = {};

  activeKind = KINDS.BEV;

  activeGroup = 'all';

  carsNavIsVisible = false;

  carDetailsIsVisible = false;

  activeCar = null;

  activeCarKind = null;

  isLoaded = false;

  isLoading = false;

  error = null;

  activeSwiperIndex = 0;

  getCars = async ({ dealerNumber }) => {
    if (!this.isLoaded) {
      try {
        this.isLoading = true;
        const {
          data: { phev = [], bev = [], equivalents },
        } = await API(APIRoutes.CARS({ dealerNumber }));

        const phevGroups = phev.map(({ name }, dataId) => ({
          text: name,
          id: name,
          dataId,
        }));

        const bevGroups = bev.map(({ name }, dataId) => ({
          text: name,
          id: name,
          dataId,
        }));

        runInAction(() => {
          this.carKinds.phev.groups = [ALL_GROUPS, ...phevGroups];
          this.carKinds.bev.groups = [ALL_GROUPS, ...bevGroups];
          this.carKinds.bev.vehicles = bev;
          this.carKinds.phev.vehicles = phev;
          this.mapped = toSlugMap(bev, phev, 'fullName');
          this.equivalents = equivalents;
          this.isLoaded = true;
        });
      } catch (error) {
        runInAction(() => {
          this.error = error;
        });
        console.warn(error);
      } finally {
        runInAction(() => {
          this.isLoading = false;
        });
      }
    }
  };

  scrollIntoFirstStep = () => {
    const stepElement = document.getElementById('section-01');

    stepElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  setActiveKind = (kind) => {
    if (kind !== this.activeKind) {
      this.activeKind = kind;
      this.activeGroup = 'all';
      this.carDetailsIsVisible = false;
      this.scrollIntoFirstStep();
    }
  };

  get isActiveKindPhev() {
    return this.activeKind === KINDS.PHEV;
  }

  setActiveGroup = (group) => {
    this.activeGroup = group;
    this.carDetailsIsVisible = false;
    this.scrollIntoFirstStep();
  };

  setActiveCar = ({ car = null, group = 'all' }) => {
    this.activeCar = car;
    this.activeCarKind = this.activeKind;
    this.carDetailsIsVisible = true;
    this.activeGroup = group;
    history.push({
      search: `?model=${toSlug(car.fullName)}`,
    });
    if (car) {
      this.upateInterface();
    }
  };

  get isActiveCarPhev() {
    return this.activeCarKind === KINDS.PHEV;
  }

  setActiveCarFromMap = (model) => {
    const car = this.getCarByModel(model);
    const { type, group } = car;
    this.activeCar = car;
    this.activeSwiperIndex = car.index;
    this.activeKind = type;
    this.activeGroup = group;
    this.activeCarKind = type;
    this.carDetailsIsVisible = true;

    setTimeout(() => {
      runInAction(() => {
        this.upateInterface();
      });
    }, [REDIRECTION_DELAY]);
  };

  setActiveCarKind = (kind) => {
    this.activeCarKind = kind;
  };

  updateIconLinksFooter = (car) => {
    const { testDriveLink, nclSearchLink, configuratorLink } = car;

    if (isNotEmptyString(testDriveLink)) {
      commonsStore.links[0].currentUrl = testDriveLink;
    }

    if (isNotEmptyString(nclSearchLink)) {
      commonsStore.links[1].currentUrl = nclSearchLink;
    }

    if (isNotEmptyString(configuratorLink)) {
      commonsStore.links[3].currentUrl = configuratorLink;
    }
  };

  resetIconLinksFooter = () => {
    commonsStore.links[0].currentUrl = null;
    commonsStore.links[1].currentUrl = null;
    commonsStore.links[3].currentUrl = null;
  };

  upateInterface = () => {
    if (this.activeKind === KINDS.BEV) {
      calculatorStore.getBevSavings();
    } else {
      calculatorStore.getPhevSavings();
    }
    this.updateIconLinksFooter(this.activeCar);
  };

  showCarsNav = (state) => {
    this.carsNavIsVisible = state;
  };

  showCarDetails = (state) => {
    this.carDetailsIsVisible = state;
  };

  resetCars = () => {
    this.activeKind = KINDS.BEV;
    this.activeGroup = 'all';
    this.carDetailsIsVisible = false;
    this.activeCar = null;
    this.activeCarKind = null;
    this.resetIconLinksFooter();
  };

  getCarByModel = (id) => this.mapped[id];
}

export default new CarsStore();
