import styled from '@emotion/styled/macro';
import { InputBase } from '@components/Form/style';

export const RadioCheckMark = styled.span`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;
  border: 2px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 50%;

  &:after {
    content: '';
    position: absolute;
    display: none;
    top: 8px;
    left: 8px;
    bottom: 8px;
    right: 8px;
    border-radius: 50%;
    background-color: #bbd2f2;
  }
`;

export const Radio = styled(InputBase)`
  & input:checked ~ ${RadioCheckMark} {
    background-color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
  }

  & input:checked ~ ${RadioCheckMark}:after {
    display: block;
  }
`;
