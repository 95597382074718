import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import { Annotation, Container } from '@styles/globalStyles';

import Section from '@components/Section';
import { ContentWrapper } from '@containers/DataPage/style';

const DataPage = ({
  pagesStore: {
    dataPage: {
      data: { headline, description, content, annotation },
    },
    getPage,
  },
  page,
}) => {
  useEffect(() => {
    getPage({ page, key: 'dataPage' });
  }, [page]);

  return (
    <Container>
      {(headline || description) && (
        <Section title={headline} content={description} />
      )}
      <ContentWrapper dangerouslySetInnerHTML={{ __html: content }} />
      {annotation && (
        <Annotation dangerouslySetInnerHTML={{ __html: annotation }} />
      )}
    </Container>
  );
};

DataPage.propTypes = {
  pagesStore: PropTypes.shape({
    phev: PropTypes.shape({
      data: PropTypes.object,
      isLoading: PropTypes.bool,
    }),
    getPage: PropTypes.func,
  }).isRequired,
};

export default inject('pagesStore')(observer(DataPage));
