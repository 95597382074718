import styled from '@emotion/styled/macro';
import { InputBase } from '@components/Form/style';

export const CheckMark = styled.span`
  position: absolute;
  height: 24px;
  width: 24px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.lightGray};

  &:after {
    content: '';
    position: absolute;
    display: none;

    left: 6px;
    top: 1px;
    width: 8px;
    height: 14px;
    border: solid #bbd2f2;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

export const Checkbox = styled(InputBase)`
  & input:checked ~ ${CheckMark} {
    background-color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
  }

  & input:checked ~ ${CheckMark}:after {
    display: block;
  }
`;
