import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

import { Container, Annotation } from '@styles/globalStyles';
import { PAGES } from '@routes';
import BenefitsSection, {
  IntroductionSection,
} from '@components/BenefitsSection';
import Banner, { BANNER_VARIANTS } from '@components/Banner';
import VideoSection from '@components/BenefitsSection/video-section';

const FIND_YOUR_BMW = 'find_your_bmw';

const Electrics = ({
  pagesStore: {
    electric: { data },
    getPage,
  },
  retailerStore: { dealerNumber, isDealerSet },
}) => {
  const [findYourBmwSection, setFindYourBmwSection] = useState();
  const [banners, setBanners] = useState([]);
  const [sectionsData, setSectionsData] = useState([]);

  useEffect(() => {
    isDealerSet && getPage({ page: PAGES.electric, dealerNumber });
  }, [isDealerSet]);

  useEffect(() => {
    setFindYourBmwSection(
      data?.pageSections?.filter((s) => s.slug === FIND_YOUR_BMW)[0] ||
        undefined,
    );
    setSectionsData(
      data?.pageSections?.filter((s) => s.slug !== FIND_YOUR_BMW) || [],
    );
    setBanners(data?.banners || []);
  }, [data]);

  return (
    <>
      <Helmet>
        <title>Electric Benefits | BMW Electrified</title>
      </Helmet>
      {findYourBmwSection ? <VideoSection section={findYourBmwSection} /> : ''}
      <Container>
        <IntroductionSection content={data.description} title={data.headline} />
      </Container>
      {sectionsData.map((section, index) => (
        <BenefitsSection key={`lp-section_${index}`} section={section} />
      ))}
      {banners.map((banner, index) => (
        <Banner
          key={`banner-${index}`}
          {...banner}
          variant={BANNER_VARIANTS.BENEFIT}
        />
      ))}
      {data.annotation && (
        <Container>
          <Annotation dangerouslySetInnerHTML={{ __html: data.annotation }} />
        </Container>
      )}
    </>
  );
};

Electrics.propTypes = {
  pagesStore: PropTypes.shape({
    phev: PropTypes.shape({
      data: PropTypes.object,
      isLoading: PropTypes.bool,
    }),
    getPage: PropTypes.func,
  }).isRequired,
  retailerStore: PropTypes.object.isRequired,
};

export default inject('pagesStore', 'retailerStore')(observer(Electrics));
