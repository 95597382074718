import styled from '@emotion/styled/macro';
import { breakpoint } from '@styles/breakpoints';

import { InfoIcon } from '@styles/globalStyles';

export const InfoButton = styled(InfoIcon)`
  position: absolute;
  right: 13px;
  top: 13px;
`;

export const Wrapper = styled.div`
  background-color: #ffffff;

  z-index: 3;
  padding: 10px 12px 10px;

  text-align: left;
  max-width: 100%;

  @media ${breakpoint.md} {
    padding: 18px;
    width: 340px;
  }
`;

export const BaseText = styled.div`
  color: #262626;
`;

export const Label = styled(BaseText)`
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.darkGray};
  ${({ hideMargin }) =>
    !hideMargin &&
    `
  margin-bottom:  8px;
  `}
`;

export const RowBase = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-size: 14px;
`;

export const AnnualRow = styled(RowBase)`
  background-color: rgba(230, 230, 230, 0.25);
  padding: 15px 32px;
  margin: 16px -32px 10px;

  ${Label} {
    margin-bottom: 0;
  }
`;

export const Row = styled(RowBase)`
  padding: 10px 0 13px;
  border-bottom: 1px #e6e6e6 solid;
`;

export const TextRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const Value = styled(BaseText)`
  font-family: ${({ theme }) => theme.fonts.bold};
  min-width: 100px;
  text-align: right;
`;

export const Header = styled.div`
  font-family: ${({ theme }) => theme.fonts.light};

  > h2 {
    color: #000;
    display: block;
    font-size: ${({ theme }) => theme.fontSizes[2]}px;
    text-transform: uppercase;

    &:after {
      display: inline;
      content: '.';
    }
  }

  > p {
    color: #262626;
    font-size: 15px;
    margin-bottom: 5px;
  }
`;
