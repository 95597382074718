import styled from '@emotion/styled';
// import { css } from '@emotion/react';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.banners.maxWidth}px;
  margin: 0 auto;
  display: block;
  position: relative;
  min-height: ${({ theme }) => theme.banners.heightMobile}px;

  // background: linear-gradient(180deg, #142745 0%, #41678b 100%);
  background: #ffffff;

  @media ${breakpoint.tabletPortrait} {
    min-height: ${({ theme }) => theme.banners.heightTablet}px;
  }

  @media ${breakpoint.lg} {
    min-height: ${({ theme }) => theme.banners.height}px;
  }
`;

export const Banner = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media ${breakpoint.lg} {
    color: #fff;
    display: block;

    img {
      max-height: 100vh;
      object-fit: cover;
    }
  }

  picture {
    order: 0;
  }
`;

export const TextWrapper = styled.div`
  order: 1;
  padding-top: 40px;
  padding-bottom: 5px;
  text-align: left;

  @media ${breakpoint.lg} {
    padding-top: 60px;
    padding-bottom: 12px;
  }

  @media ${breakpoint.lg} {
    bottom: 0;
    display: block;
    left: 0;
    padding-top: 145px;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.light};
  font-size: 36px;
  line-height: 1;
  font-weight: 300;
  margin-top: 0;
  text-transform: uppercase;

  @media ${breakpoint.tabletAndBeyond} {
    font-size: 48px;
  }

  @media ${breakpoint.lg} {
    font-size: 50px;
  }

  @media ${breakpoint.md} {
    margin-top: 0;
  }
`;

export const Subtitle = styled.span`
  font-family: ${({ theme }) => theme.fonts.light};
  font-size: ${({ theme }) => theme.fontSizes[1]}px;
  font-weight: 300;

  @media ${breakpoint.xs} {
    font-size: ${({ theme }) => theme.fontSizes[2]}px;
  }
  @media ${breakpoint.sm} {
    font-size: ${({ theme }) => theme.fontSizes[3]}px;
  }
`;

export const Slider = styled.div`
  @media ${breakpoint.lg} {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-pagination {
    display: ${({ enabled }) => (enabled ? 'block' : 'none')};
  }
`;
