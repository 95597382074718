import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const GroupLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  margin: 12px 0 24px;

  @media ${breakpoint.md} {
    margin: 24px 0;
  }
`;

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media ${breakpoint.tabletPortrait} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${breakpoint.md} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const InfoTooltipBody = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[0]}px;
  font-family: ${({ theme }) => theme.fonts.light};
  line-height: 16px;
  padding: 8px;

  @media ${breakpoint.tabletPortrait} {
    max-width: 420px;
    min-width: 420px;
    padding: 18px;
  }

  @media ${breakpoint.md} {
    max-width: 460px;
    min-width: 460px;
  }
`;
