function parseSearch(i) {
  const o = {};
  i.substr(1)
    .split('&')
    .forEach((item) => {
      const [key, value] = item.split('=');
      o[key] = value;
    });
  return o;
}

export default parseSearch;
