import { useState } from 'react';

import debounce from '@utils/debounce';

function getMatch(bp) {
  return window.matchMedia(bp).matches;
}

function useMatchBreakpoint(bp) {
  const [matches, setMatches] = useState(getMatch(bp));

  window.addEventListener(
    'resize',
    debounce(() => setMatches(getMatch(bp))),
  );

  return matches;
}

export default useMatchBreakpoint;
