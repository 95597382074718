/* eslint-disable no-nested-ternary */
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { breakpoint } from '@styles/breakpoints';

export const StepperWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 18px 0 5px;
`;

export const StepperLabelsWrapper = styled.div`
  display: inline-block;
  font-size: 14px;
  width: 100%;
  height: 14px;
  font-family: ${(props) => props.theme.fonts.bold};
  padding: 0 5px 0 10px;

  @media ${breakpoint.tabletPortrait} {
    position: absolute;
    z-index: -1;
  }
`;

export const StepperLabel = styled.div`
  display: inline-block;
  float: ${(props) => (props.right ? 'right' : 'left')};
`;

export const StepPopUp = styled.div`
  display: none;
`;

export const DotWrapper = styled.div`
  width: 29px;
  height: 29px;
  border-radius: 50%;
  background-color: transparent;
  padding: 5px;

  &:hover,
  &:active {
    background-color: rgba(6, 83, 182, 0.3);

    ${StepPopUp} {
      display: block;
      white-space: nowrap;
      position: absolute;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
      background: white;
      font-family: ${(props) => props.theme.fonts.bold};
      font-size: 14px;
      line-height: 34px;
      padding: 0 10px;
      text-align: center;
      margin-left: -8px;
    }

    &:first-of-type > ${StepPopUp}, &:nth-of-type(6) > ${StepPopUp} {
      display: none;
    }
  }
`;

export const Dot = styled.div`
  width: 5px;
  height: 5px;
  margin: 7px;
  background-color: #bbbbbb;
  border-radius: 50%;

  ${(props) =>
    props.isFirst &&
    css`
      width: 11px;
      height: 11px;
      margin: 4px;
      background-color: #1c69d4;
    `}

  ${(props) =>
    props.active &&
    css`
      width: 19px;
      height: 19px;
      margin: 0px;
      background-color: #1c69d4;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: -5px;
        bottom: -5px;
        right: -5px;
        left: -5px;
        background-color: rgba(6, 83, 182, 0.3);
        z-index: -1;
        border-radius: 50%;
      }

      &:after {
        content: '';
        position: absolute;
        top: -10px;
        bottom: -10px;
        right: -10px;
        left: -10px;
        background-color: rgba(6, 83, 182, 0.1);
        z-index: -2;
        border-radius: 50%;
      }
    `}
`;

export const ArrowUp = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  position: absolute;
  top: -8px;
  left: 8px;
`;

export const DashWrapper = styled.div`
  width: 100%;
  padding: 0 19px;
  position: absolute;
  left: 0px;
  display: block;
  overflow: hidden;
  z-index: 1;
  pointer-events: none;
`;

export const Dash = styled.div`
  width: ${(props) => `${props.percentage}%`};
  background-color: #1c69d4;
  height: 5px;
  margin-bottom: 22px;
  display: inline-block;
`;

export const RestDash = styled.div`
  width: ${(props) => `${100 - props.percentage}%`};
  background-color: #bbbbbb;
  height: 3px;
  margin: 18px 0;
  display: inline-block;
`;
