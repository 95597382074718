import styled from '@emotion/styled';
import { Tab } from '@components/Tabs/style';

export const NavWrapper = styled.nav`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;

  background: #fff;
  padding: 6px 0;

  z-index: 9;

  margin-top: -52px;
  margin-bottom: 82px;

  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  pointer-events: ${(props) => (props.isVisible ? 'initial' : 'none')};
`;

export const GroupsTab = styled(Tab)`
  color: ${(props) => (props.active ? '#262626' : '#666666')};
`;
