import React from 'react';
import styled from '@emotion/styled/macro';
import { Global, css } from '@emotion/react';
import { size } from '@styles/breakpoints';
import CloseIcon from '@assets/images/icons/close.svg';
import { RESET_BUTTON } from '@styles/globalStyles';

const ARROW_SIZE = 40;

export const TooltipArrow = styled.div`
  height: ${ARROW_SIZE}px;
  position: absolute;
  width: ${ARROW_SIZE}px;
  pointer-events: none;

  &::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin: auto;
    background: var(--tooltipBackground);
  }
`;

export const TooltipBody = styled.div`
  min-width: ${({ minWidth }) => minWidth || '130px'};
  width: 100%;
  height: 100%;

  overflow: hidden;
`;

export const TooltipContainer = styled.div`
  --tooltipBackground: #fff;
  --tooltipBorder: rgba(13, 13, 13, 0.12);

  transition: opacity 0.3s;
  z-index: 99;

  background-color: var(--tooltipBackground);

  box-shadow: 0px 3px 6px #00000029;
  color: #000;

  padding: 12px;

  &[data-popper-interactive='false'] {
    ${TooltipBody} {
      pointer-events: none;
    }
  }

  &[data-popper-placement*='bottom'] {
    ${TooltipArrow} {
      top: 0;
      left: 0;
      margin-top: -${ARROW_SIZE / 2}px;
      filter: drop-shadow(0 -3px 2px #00000015);

      &::before {
        width: 100%;
        height: 50%;
        clip-path: polygon(50% 0, 0 100%, 100% 100%);
      }
    }
  }

  &[data-popper-placement*='top'] {
    ${TooltipArrow} {
      bottom: 0;
      left: 0;
      margin-bottom: -${ARROW_SIZE}px;
      filter: drop-shadow(0 3px 2px #00000029);

      &::before {
        width: 100%;
        height: 50%;
        clip-path: polygon(50% 100%, 0 0, 100% 0);
      }
    }
  }

  &[data-popper-placement*='right'] {
    ${TooltipArrow} {
      left: 0;
      margin-left: -${0.7 * ARROW_SIZE}px;
      filter: drop-shadow(-2px 2px 2px #00000015);

      &::before {
        width: 50%;
        height: 100%;
        clip-path: polygon(100% 0, 0 50%, 100% 100%);
      }
    }
  }

  &[data-popper-placement*='left'] {
    ${TooltipArrow} {
      margin-right: -${0.7 * ARROW_SIZE}px;
      right: 0;
      filter: drop-shadow(2px 2px 2px #00000015);

      &::before {
        width: 50%;
        height: 100%;
        clip-path: polygon(0 0, 100% 50%, 0 100%);
      }
    }
  }
`;

export const TooltipTransitions = () => (
  <Global
    styles={css`
      .tooltip-transition-enter {
        opacity: 0;
      }

      .tooltip-transition-enter-active {
        opacity: 1;
        transition: opacity 2s;
      }

      .tooltip-transition-exit {
        opacity: 1;
      }

      .tooltip-transition-exit-active {
        opacity: 0;
        transition: opacity 2s;
      }
    `}
  />
);

export const Wrap = styled.div`
  .tooltip-transition-appear-active,
  .tooltip-transition-enter-active,
  .tooltip-transition-enter-done {
    opacity: 1;
    transition: all 200ms ease-out;
  }

  .tooltip-transition-appear,
  .tooltip-transition-enter,
  .tooltip-transition-exit-active,
  .tooltip-transition-exit-done {
    opacity: 0;
    transition: all 200ms ease-out;
  }
`;

export const InfoTooltipContainer = styled.div`
  z-index: 99;
`;

export const InfoTooltipClose = styled.button`
  ${RESET_BUTTON()}
  width: 15px;
  height: 15px;
  background: transparent url(${CloseIcon}) no-repeat 50% 50% / contain;
  position: absolute;
  top: 15px;
  right: 15px;
  display: none;
`;

export const InfoTooltipBodyStyle = styled(TooltipContainer)`
  @media (max-width: ${size.tabletPortrait}px) {
    width: calc(100vw - 20px);
    margin-left: 10px;
    transform: translateX(-10px);
    padding-top: 30px;
    padding-bottom: 20px;

    ${InfoTooltipClose} {
      display: block;
    }
  }
`;
