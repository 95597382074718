import styled from '@emotion/styled/macro';

export const InputContainer = styled.div``;

export const InputIndicatorWrapper = styled.div`
  position: absolute;
  right: 15px;
  bottom: 12px;

  svg {
    vertical-align: middle;
    width: 20px;

    path {
      fill: ${({ theme, withValue }) =>
        withValue ? theme.colors.darkGray : theme.colors.lightGray};
    }
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-top: 14px;
`;

export const Input = styled.input`
  height: 50px;
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.lightGray};
  padding: 0;
  padding-left: 15px;
  padding-right: ${({ withIndicator }) => (withIndicator ? 40 : 15)}px;
  font-size: 14px;
  font-weight: bold;
  font-family: ${(props) => props.theme.fonts.bold};
  border-radius: 0px !important;

  transition: all 0.2s;

  &:focus {
    outline-width: 0;
    outline: none;
  }

  &:focus,
  &:hover {
    border-color: white;
    box-shadow: 0px 3px 6px #00000026;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.lightGray};
  }

  &:-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.lightGray};
  }

  &::-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.lightGray};
  }

  /* Fix for ugly autofill background color */
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    &:hover,
    &:focus {
      box-shadow: 0px 3px 6px #00000026, 0 0 0 30px white inset !important;
    }
  }

  &:autofill {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    &:hover,
    &:focus {
      box-shadow: 0px 3px 6px #00000026, 0 0 0 30px white inset !important;
    }
  }
`;
