/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box } from 'rebass';
import ReactGA from 'react-ga4';

import useMatchBreakpoint from '@root/hooks/useMatchBreakpoint';
import {
  Item,
  StyledSwiperWrapper,
  Text,
  Title,
  Description,
  Background,
  Car,
  CarBase,
  CarCover,
  Button,
  SwiperNavigation,
  NavArrow,
} from '@containers/Home/components/CarsSlider/components/style';
import { breakpoint } from '@styles/breakpoints';

import arrowLeft from '@assets/images/icons/arrow_left.svg';
import arrowRight from '@assets/images/icons/arrow_right.svg';
import tickIcon from '@assets/images/icons/tick.svg';

const swiperSettings = {
  slidesPerView: 1,
  loop: true,
  allowTouchMove: false,
  effect: 'fade',
  speed: 1000,
};

const CarItem = ({
  name,
  group,
  vehicles = [],
  setActiveCar,
  activeCarId,
  innerIndex,
  isActive,
}) => {
  const ref = useRef(null);
  const [isFocus, setIsFocus] = useState(false);

  const isDesktop = useMatchBreakpoint(breakpoint.md);

  const swiperEnabled = vehicles?.length > 1;

  const onSelect = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveCar({ car: vehicles[index], group });

    let vehicleName = 'Unknown';

    if (vehicles[index].fullName) {
      vehicleName = vehicles[index].fullName;
    }

    sendAnalyticsEvent(vehicleName, group);
  };

  const sendAnalyticsEvent = (vehicleName, groupName) => {
    ReactGA.event({
      category: 'Vehicle Selection',
      action: `${vehicleName}`,
      label: `Group: ${groupName}`,
    });
  };

  const onSlideChange = (e, next) => {
    e.preventDefault();

    if (next) {
      ref.current?.swiper.slideNext();
    } else {
      ref.current.swiper.slidePrev();
    }
  };

  useEffect(() => {
    if (ref.current?.swiper && typeof innerIndex === 'number') {
      ref.current?.swiper.slideTo(innerIndex + 1, 0, false);
    }
  }, [innerIndex]);

  useEffect(() => {
    if (!isDesktop && !isActive) {
      setIsFocus(false);
    }
  }, [isActive, isDesktop]);

  const swiperNav = swiperEnabled && (
    <SwiperNavigation>
      <NavArrow onClick={(e) => onSlideChange(e)}>
        <img src={arrowLeft} alt="" />
      </NavArrow>
      <NavArrow onClick={(e) => onSlideChange(e, true)}>
        <img src={arrowRight} alt="" />
      </NavArrow>
    </SwiperNavigation>
  );

  return (
    <StyledSwiperWrapper enabled={swiperEnabled}>
      <Item
        isFocus={isFocus}
        onMouseLeave={() => isDesktop && setIsFocus(false)}
        onMouseEnter={() => isDesktop && setIsFocus(true)}
      >
        <Swiper ref={ref} {...swiperSettings}>
          {vehicles.map(
            (
              {
                id,
                fullName: model,
                mainImage,
                hoverImage,
                gradientStart,
                gradientEnd,
                mainImageMobile,
                hoverImageMobile,
              },
              index,
            ) => (
              <SwiperSlide key={`inner-vehicle-${id}`}>
                <div onClick={() => setIsFocus(!isFocus)}>
                  <Background
                    gradientStart={gradientStart}
                    gradientEnd={gradientEnd}
                  />
                  <div>
                    <Text onClick={(e) => isDesktop && onSelect(e, index)}>
                      <Title>{name}</Title>
                      <Description>{model}</Description>
                    </Text>
                    <Car onClick={(e) => isDesktop && onSelect(e, index)}>
                      <CarBase
                        src={isDesktop ? mainImage : mainImageMobile}
                        alt=""
                      />
                      <CarCover
                        src={isDesktop ? hoverImage : hoverImageMobile}
                        alt=""
                      />
                    </Car>
                  </div>
                </div>
                <Box width={1} textAlign="center">
                  <Button
                    onClick={(e) => onSelect(e, index)}
                    variant="primary"
                    isSelected={activeCarId === id}
                  >
                    <span>Select this model</span>
                    <span>
                      <img src={tickIcon} alt="" /> Selected
                    </span>
                  </Button>
                </Box>
              </SwiperSlide>
            ),
          )}
        </Swiper>
        {isDesktop && swiperNav}
      </Item>
      {!isDesktop && !isFocus && swiperNav}
    </StyledSwiperWrapper>
  );
};

CarItem.defaultProps = {
  activeCarId: undefined,
  innerIndex: undefined,
};

CarItem.propTypes = {
  name: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
  vehicles: PropTypes.array.isRequired,
  setActiveCar: PropTypes.func.isRequired,
  activeCarId: PropTypes.number,
  innerIndex: PropTypes.number,
};

export default CarItem;
