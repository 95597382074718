import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button } from '@styles/globalStyles';
import { breakpoint } from '@styles/breakpoints';

export const MapContainer = styled.div`
  background: white;
  margin: 30px 0;
  height: 400px;
  width: 100%;
  max-width: 100%;
  position: relative;
  transition: all 0.5s ease-in-out;

  /*  workaround for map's display:none bug */
  position: absolute;
  left: -150%;

  ${({ show }) =>
    show
      ? css`
          position: relative;
          left: unset;
        `
      : null};
  /*  workaround for map's display:none bug */

  ${({ loading }) =>
    loading
      ? css`
          filter: grayscale(1);
          &:before {
            opacity: 1;
          }
        `
      : null}

  @media ${breakpoint.tabletPortrait} {
    position: relative;
    left: unset;
    height: 372px;
  }

  @media ${breakpoint.md} {
    margin: 50px 0;
    height: 650px;
  }

  &:before {
    content: 'loading...';
    font-size: 18px;
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    justify-content: center;
    align-items: center;
    z-index: 10;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .map-cluster-icon {
    background: white;
    border: 3px solid #1c69d4;
    border-radius: 100%;
    color: #262626;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px !important;
    height: 40px !important;

    span {
      font-family: ${(props) => props.theme.fonts.bold};
      font-size: 12px;
      color: ${(props) => props.theme.colors.text};
    }

    img {
      display: none;
    }

    > div {
      font-size: 14px !important;
    }
  }

  .point-details {
    max-width: 300px;
    font-family: 'BMW-Light';

    strong {
      font-family: 'BMW-Bold';
    }

    > h2 {
      font-size: 18px;
      font-weight: 300;
      line-height: 26px;
      display: block;
      margin: 10px 0;
    }

    > h3 {
      font-size: 14px;
      line-height: 18px;
      display: block;
      margin-bottom: 10px;
    }

    ul {
      li {
        padding: 3px 0;
        font-size: 14px;
      }
    }

    &__accessibility {
      margin-top: 10px;
      & > strong {
        display: inline-block;
        margin-bottom: 5px;
      }
    }
  }
`;

export const EditSearchButton = styled(Button)`
  margin: 10px auto;
`;
