import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Route from '@components/Route';
import routes, { PAGES, retailerRoutes } from '@routes';
import Electrics from '@containers/Electrics';
import Phev from '@containers/Phev';
import Home from '@containers/Home';
import DataPage from '@containers/DataPage';
import StaticPage from '@containers/DataPage/CookiePage';

const RetailerSite = () => (
  <Switch>
    <Route exact path={retailerRoutes.phev} page={PAGES.phev}>
      <Phev />
    </Route>
    <Route exact path={retailerRoutes.electric} page={PAGES.electric}>
      <Electrics />
    </Route>
    <Route exact path={retailerRoutes.chargingFaqs}>
      <DataPage page={PAGES.chargingFaqs} />
    </Route>
    <Route exact path={retailerRoutes.calculationsExplained}>
      <DataPage page={PAGES.calculationsExplained} />
    </Route>
    <Route exact path={retailerRoutes.electricCalculationsExplained}>
      <DataPage page={PAGES.electricCalculationsExplained} />
    </Route>
    <Route exact path={retailerRoutes.cookiePolicy}>
      <StaticPage />
    </Route>
    <Route exact strict path={retailerRoutes.homepage} page={PAGES.homepage}>
      <Home />
    </Route>
    <Redirect
      exact
      strict
      from={retailerRoutes.homepage.slice(0, -1)}
      to={`${retailerRoutes.homepage}`}
    />
    <Redirect from="*" to={routes.homepage} push />
  </Switch>
);

export default RetailerSite;
