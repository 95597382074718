import styled from '@emotion/styled/macro';
import NumberFormat from 'react-number-format';
import { breakpoint } from '@styles/breakpoints';

export const TilesRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0 -20px;
  flex-wrap: wrap;

  @media ${breakpoint.md} {
    margin: 20px 0 40px;
  }
`;

export const Title = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes[3]}px;
  margin-bottom: 8px;
`;

export const Label = styled.span`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray};
`;

export const Line = styled.div`
  position: absolute;
  height: 5px;
  background-color: ${({ theme }) => theme.colors.primary};
  top: 0;
  left: 0;
  width: ${(props) => (props.selected ? 100 : 0)}%;
  opacity: ${(props) => (props.selected ? 1 : 0)};
  transition: width 0.5s cubic-bezier(1, 0.4, 0, 0.99), opacity 0.3s ease-in-out;
`;

export const Box = styled.div`
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  flex: 0 0 100%;
  padding: 30px 35px;
  position: relative;
  cursor: pointer;
  opacity: ${(props) => (props.selected ? 1 : 0.3)};
  transition: opacity 0.3s ease-in-out;
  margin-bottom: 20px;

  &:hover {
    opacity: 1;

    ${Line} {
      width: 100%;
      opacity: 1;
    }
  }

  @media ${breakpoint.md} {
    flex: 0 0 32.2%;
    margin-bottom: 0;

    .ie-11 & {
      flex: 1 !important;

      &:nth-of-type(2) {
        margin: 0 15px;
      }
    }
  }
`;

export const Approximately = styled(NumberFormat)`
  font-size: ${({ theme }) => theme.fontSizes[3]}px;
  font-family: ${({ theme }) => theme.fonts.light};
  margin: 8px 0 15px;
  display: block;
`;

export const InfoButton = styled.div`
  border-radius: 100%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.lightGray};
  color: #ffffff;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 14px;
  padding: 0;
  text-align: center;
  position: absolute;
  right: 13px;
  top: 13px;

  span {
    line-height: 20px;
  }
`;
