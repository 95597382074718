import { makeAutoObservable } from 'mobx';

export class RetailerStore {
  constructor() {
    makeAutoObservable(this);
  }

  isDealerSet = false;

  dealerNumber = undefined;

  isRetailer = false;

  setDealerNumber = (dealerNumber) => {
    this.dealerNumber = dealerNumber;
    this.isDealerSet = true;
    this.isRetailer = !!dealerNumber;
  };
}

export default new RetailerStore();
