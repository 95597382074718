import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';
import { HEADING } from '@styles/globalStyles';
import { CARS_NAV_HEIGHT } from '@root/stores/carsStore';

export const SectionWrapper = styled.section`
  margin-bottom: 60px;
  scroll-margin-top: -${CARS_NAV_HEIGHT}px;

  @media ${breakpoint.md} {
    margin-bottom: 80px;
  }

  &:first-of-type {
    h2 {
      display: inline-block;
    }
  }
`;

export const SectionTitle = styled.h2`
  ${({ theme }) => HEADING(theme)}
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizes[4]}px;
  margin-bottom: 2px;
  line-height: 32px;
  display: inline-block;

  @media ${breakpoint.tabletPortrait} {
    font-size: ${({ theme }) => theme.fontSizes[5]}px;
    line-height: 1;
    margin-bottom: 8px;
  }
`;

export const SectionNumber = styled.span`
  ${({ theme }) => HEADING(theme)}
  font-size: ${({ theme }) => theme.fontSizes[4]}px;
  margin-right: 8px;
  color: #bbbbbb;
  display: block;

  @media ${breakpoint.tabletPortrait} {
    display: inline-block;
    font-size: ${({ theme }) => theme.fontSizes[5]}px;
  }
`;

export const SectionContent = styled.p`
  margin-bottom: 50px;
`;

export const SectionContentWithForcedNormalFontWeight = styled.p`
  margin-bottom: 50px;

  strong {
    font-weight: 400;
  }
`;

export const SectionAnnotation = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[0]}px;
  margin-top: 16px;
`;
