/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import styled from '@emotion/styled';

const VideoSection = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  .video--outer {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .video--responsive {
    position: relative;
    width: 100%;
  }

  .video--ratio_16_9 {
    padding-top: 56.25%;
  }

  .video--wraper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
`;

const VudooVideo = ({ link }) => (
  <VideoSection className="vudoo--embed">
    <div className="video--outer">
      <div className="video--responsive video--ratio_16_9">
        <div className="video--wraper">
          <iframe src={link} frameBorder="0" allowFullScreen />
        </div>
      </div>
    </div>
  </VideoSection>
);
export default VudooVideo;
