import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select';
import theme from '@styles/theme';
import { InputWrapper, Value } from './style';

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="9.375"
      viewBox="0 0 15 9.375"
    >
      <path
        fill="#bbb"
        d="M0,1.875,5.625,7.5,0,13.125,1.875,15l7.5-7.5L1.875,0Z"
        transform="translate(15) rotate(90)"
      />
    </svg>
  </components.DropdownIndicator>
);

const Select = ({ label, value, ...props }) => {
  const styles = {
    control: (base, { selectProps, hasValue }) => ({
      ...base,
      fontSize: '14px',
      fontFamily: theme.fonts.bold,
      borderRadius: 0,
      borderWidth: 2,
      color: selectProps.menuIsOpen ? '#FFFFFF' : '#BBBBBB',
      borderColor: 'currentColor',
      boxShadow: selectProps.menuIsOpen ? '0px 3px 6px #00000026' : 'unset',
      cursor: 'pointer',
      position: 'relative',
      height: 50,

      ':hover': {
        color: '#ffffff',
        borderColor: 'currentColor',
        boxShadow: ' 0px 3px 6px #00000026',
        '&:before': {
          backgroundColor: 'transparent',
        },
        '& .option-value': {
          display: 'none',
        },
      },
      ':focus-within': {
        borderColor: 'currentColor',
        boxShadow: ' 0px 3px 6px #00000026',
      },
      ':before': {
        content: '" "',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        width: 4,
        backgroundColor:
          !selectProps.menuIsOpen && hasValue ? '#1c69d4' : 'transparent',
        margin: -2,
        transition: 'background .2s ease',
      },
    }),
    placeholder: (base) => ({
      ...base,
      color: theme.colors.text,
    }),
    dropdownIndicator: (base, { selectProps }) => ({
      ...base,
      color: '#BBBBBB',
      transition: 'all .2s ease',
      transform: selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      width: 46,
      justifyContent: 'center',
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '2px 18px',
      minHeight: 46,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      margin: 0,
      width: 2,
      backgroundColor: 'currentcolor',
    }),
    menu: (base) => ({
      ...base,
      fontFamily: theme.fonts.bold,
      color: theme.colors.gray,

      borderRadius: 0,
      marginTop: 0,
      border: 'none',
      borderTop: '2px solid #e6e6e6',
      boxShadow: '0px 4px 10px #0000001A',
    }),
    option: (base, { isSelected }) => ({
      ...base,
      padding: '11px 20px 10px',
      backgroundColor: 'transparent',
      color: 'inherit',
      cursor: 'pointer',
      position: 'relative',
      '&:before': {
        content: "''",
        position: 'absolute',
        top: 7,
        left: 0,
        width: 4,
        height: 30,
        backgroundColor: `${isSelected ? '#1c69d4' : '#ffffff'}`,
      },

      ':hover': {
        backgroundColor: 'transparent',

        '&:before': {
          backgroundColor: `#1c69d4`,
        },
      },
    }),
    singleValue: (base, { selectProps }) => ({
      ...base,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      '& .option-value': {
        display: selectProps.menuIsOpen ? 'none' : 'block',
      },
    }),
  };

  const SingleValue = ({ children, ...valueProps }) => (
    <components.SingleValue {...valueProps}>
      <span>{label}</span>
      <Value className="option-value">{children}</Value>
    </components.SingleValue>
  );

  const selectedOption = useMemo(
    () => props.options.find((o) => o.value === value) || null,
    [value],
  );

  return (
    <InputWrapper>
      <ReactSelect
        {...{
          placeholder: label,
          'aria-label': label,
          styles,
          components: {
            SingleValue,
            DropdownIndicator,
          },
          value: selectedOption,
          ...props,
        }}
      />
    </InputWrapper>
  );
};

Select.defaultProps = {
  label: undefined,
  isSearchable: false,
};

Select.propTypes = {
  label: PropTypes.string,
  isSearchable: PropTypes.bool,
};

export default Select;
