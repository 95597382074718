import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { usePopperTooltip } from 'react-popper-tooltip';
import { CSSTransition } from 'react-transition-group';

import {
  InfoTooltipContainer,
  InfoTooltipBodyStyle,
  InfoTooltipClose,
  TooltipTransitions,
} from '@components/Tooltip/style';

const InfoTooltipBody = ({ children, onClose, ...props }) => (
  <InfoTooltipBodyStyle {...props}>
    <InfoTooltipClose onClick={onClose} />
    {children}
  </InfoTooltipBodyStyle>
);

const InfoTooltip = ({ children, body, placement, offset }) => {
  const [controlledVisible, setControlledVisible] = useState(false);

  const { getTooltipProps, setTooltipRef, setTriggerRef } = usePopperTooltip({
    placement,
    offset,
    interactive: true,
    visible: controlledVisible,
    onVisibleChange: setControlledVisible,
  });

  const onClose = () => {
    /* SetTimeout to prevent reopen issue */
    setTimeout(() => {
      setControlledVisible(false);
    }, 0);
  };

  return (
    <>
      {React.cloneElement(children, { ref: setTriggerRef })}

      <TooltipTransitions />
      <CSSTransition
        in={controlledVisible}
        classNames="tooltip-transition"
        unmountOnExit
        timeout={{
          enter: 0,
          exit: 200,
        }}
      >
        <InfoTooltipContainer ref={setTooltipRef} {...getTooltipProps()}>
          <InfoTooltipBody onClose={onClose}>
            {React.cloneElement(body)}
          </InfoTooltipBody>
        </InfoTooltipContainer>
      </CSSTransition>
    </>
  );
};

InfoTooltip.defaultProps = {
  placement: 'left',
  offset: [100, 12],
};

InfoTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  placement: PropTypes.string,
  offset: PropTypes.array,
};

export default InfoTooltip;
