import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const Header = styled.div`
  font-family: ${({ theme }) => theme.fonts.light};
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
`;

export const Copy = styled.div`
  background-color: #fff;
  font-size: 14px;
  color: #262626;
  padding: 30px 0;
  margin-top: 35px;
`;

export const Wrapper = styled.footer`
  background-color: #e6e6e6;
  padding: 35px 0 0;
  margin-top: 50px;

  @media ${breakpoint.md} {
    padding: 45px 0 0;
    margin-top: 100px;
  }
`;

export const SocialRow = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  align-items: center;

  @media ${breakpoint.md} {
    align-items: flex-end;
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  padding: 27px 0 36px;
  border-bottom: 1px #bbbbbb solid;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  @media ${breakpoint.md} {
    justify-content: flex-end;
  }
`;

export const SocialLink = styled.a`
  display: flex;
  align-items: center;
  max-height: 32px;
  overflow: hidden;

  & + & {
    margin-left: 27px;
  }

  img,
  svg {
    display: block;
  }

  path {
    fill: #4d4d4d;
    stroke: transparent;
    transition: fill 0.3s ease-in-out;
    stroke-miterlimit: 10;
  }

  &:hover {
    path {
      fill: #262626;
    }
  }
`;

export const LinkBoxHeader = styled.div`
  font-family: ${({ theme }) => theme.fonts.light};
  font-weight: ${({ theme }) => theme.fontWeights.body};
  margin-bottom: 15px;
  color: #262626;
  font-size: 20px;
`;

export const Link = styled.a`
  display: block;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.bold};
  color: #666666;
  margin-bottom: 10px;
`;

export const ColumnLinks = styled.div`
  padding-left: 0;
  margin-bottom: 20px;
  width: 100%;

  @media ${breakpoint.md} {
    padding-left: 10px;
    margin-bottom: 0;
    width: auto;
    margin-right: 120px;
  }
`;
