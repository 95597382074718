import React, { useState, useEffect } from 'react';
import { number, oneOfType, shape, string } from 'prop-types';
import { useCountUp } from 'react-countup';
import { Waypoint } from 'react-waypoint';
import {
  Wrapper,
  Value,
  Title,
} from '@containers/Home/components/SavingBox/style';
import { inject, observer } from 'mobx-react';

const SavingBox = ({ commonsStore: { currencySymbol }, title, value }) => {
  const [isVisible, setIsVisible] = useState(false);

  const { countUp, start, update } = useCountUp({
    start: 0,
    end: 0,
    duration: 1,
    prefix: currencySymbol,
    separator: ',',
    decimals: 2,
    decimal: '.',
    startOnMount: false,
    redraw: true,
  });

  const handleHeaderScroll = ({ currentPosition }) => {
    if (currentPosition === 'inside') {
      setIsVisible(true);
      update(value);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    start();
  }, []);

  useEffect(() => {
    if (isVisible) {
      update(value);
    }
  }, [value, isVisible]);

  return (
    <Wrapper>
      <Waypoint onPositionChange={handleHeaderScroll} topOffset={50}>
        <Value>
          <span>{countUp}</span>
        </Value>
      </Waypoint>
      <Title>{title}</Title>
    </Wrapper>
  );
};

SavingBox.propTypes = {
  commonsStore: shape({ currencySymbol: string }).isRequired,
  title: string.isRequired,
  value: oneOfType([string, number]).isRequired,
};

export default inject('commonsStore')(observer(SavingBox));
