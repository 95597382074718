import React from 'react';
import PropTypes from 'prop-types';
import Picture from '@components/Picture';
import { LinkButton } from '@styles/globalStyles';

import {
  SectionContent,
  SectionText,
  Wrapper,
  SectionImage,
  Header,
  SubHeader,
} from '@components/BenefitsSection/style';

export { IntroductionSection } from '@components/BenefitsSection/style';

const BenefitsSection = ({ section }) => {
  const SectionBody = (
    <SectionContent>
      <Header as="h2">{section.title}</Header>
      {section.subtitle ? (
        <SubHeader as="h3">{section.subtitle}</SubHeader>
      ) : null}
      <SectionText dangerouslySetInnerHTML={{ __html: section.description }} />
      {section.link ? (
        <LinkButton
          variant={section.linkVariant}
          href={section.link}
          target={section.linkTarget}
        >
          {section.linkVariant === 'icon' ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="16"
              viewBox="0 0 10 16"
            >
              <path
                style={{ fill: '#1c69d4' }}
                d="M0,14,6,8,0,2,2,0l8,8L2,16Z"
              />
            </svg>
          ) : null}
          {section.linkText}
        </LinkButton>
      ) : null}
    </SectionContent>
  );

  if (section.backgroundImage) {
    return (
      <Wrapper
        image={section.backgroundImage}
        imageLocation={section.imageLocation}
        extraOffset={section.extraOffset}
      >
        {section.image ? (
          <SectionImage mobileOnly={!!section.backgroundImage}>
            <Picture alt={section.title} srcSet={section.image} />
          </SectionImage>
        ) : null}
        {SectionBody}
        <SectionImage />
      </Wrapper>
    );
  }

  return (
    <Wrapper
      extraOffset={section.extraOffset}
      imageLocation={section.imageLocation}
    >
      {SectionBody}

      <SectionImage>
        {section.image ? (
          <Picture alt={section.title} srcSet={section.image} />
        ) : null}
      </SectionImage>
    </Wrapper>
  );
};

BenefitsSection.propTypes = {
  section: PropTypes.object.isRequired,
};

export default BenefitsSection;
