import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Helmet } from 'react-helmet';

import Picture from '@components/Picture';
import { Container } from '@styles/globalStyles';
import {
  Banner,
  Subtitle,
  TextWrapper,
  Title,
  Wrapper,
  Slider,
} from '@components/Header/components/PageBanners/style';

const PageBanners = ({ page, commonsStore: { banners } }) => {
  const items = toJS(banners[page]);

  const swiperEnabled = items?.length > 1;

  const swiperSettings = {
    pagination: true,
    loop: true,
    allowSlideNext: swiperEnabled,
    allowSlidePrev: swiperEnabled,
    allowTouchMove: swiperEnabled,
    autoplay: {
      delay: 5000,
    },
  };

  const preloadedImages = Object.values(banners)
    .map((slides) => slides[0])
    .map(({ id, desktopImage, mobileImage, tabletImage }) => (
      <Helmet key={id}>
        <link
          rel="preload"
          href={desktopImage[0]}
          as="image"
          imageSrcSet={desktopImage
            .map((img, index) => `${img} ${index + 1}x`)
            .join(',')}
          media="(min-width: 768.1px)"
        />
        {tabletImage.length > 0 && (
          <link
            rel="preload"
            href={tabletImage[0]}
            as="image"
            imageSrcSet={tabletImage
              .map((img, index) => `${img} ${index + 1}x`)
              .join(',')}
            media="(min-width: 700.1px) and (max-width: 768px)"
          />
        )}
        {mobileImage.length > 0 && (
          <link
            rel="preload"
            href={mobileImage[0]}
            as="image"
            imageSrcSet={mobileImage
              .map((img, index) => `${img} ${index + 1}x`)
              .join(',')}
            media="(min-width: 320px) and (max-width: 700px)"
          />
        )}
      </Helmet>
    ));

  return (
    <>
      {preloadedImages}
      <Wrapper empty={!(items && items.length)}>
        {items?.length && (
          <Slider enabled={swiperEnabled}>
            <Swiper {...swiperSettings}>
              {items.map(
                (
                  { title, subtitle, desktopImage, mobileImage, tabletImage },
                  index,
                ) => (
                  <SwiperSlide key={`slide_${page}_${index}`}>
                    <Banner key={`page-banner__${index}`} image={desktopImage}>
                      <TextWrapper>
                        <Container>
                          <Title>{title}</Title>
                          <Subtitle
                            dangerouslySetInnerHTML={{ __html: subtitle }}
                          />
                        </Container>
                      </TextWrapper>
                      {desktopImage && (
                        <Picture
                          alt={title}
                          srcSet={desktopImage}
                          srcSetTablet={tabletImage}
                          srcSetMobile={mobileImage}
                        />
                      )}
                    </Banner>
                  </SwiperSlide>
                ),
              )}
            </Swiper>
          </Slider>
        )}
      </Wrapper>
    </>
  );
};

PageBanners.propTypes = {
  commonsStore: PropTypes.shape({
    banners: PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      desktopImage: PropTypes.string,
      mobileImage: PropTypes.string,
    }),
  }).isRequired,
};

export default inject('commonsStore')(observer(PageBanners));
