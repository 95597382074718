/* eslint-disable camelcase */
/* eslint-disable no-console */
import { API, APIRoutes } from '@app/api';
import { makeAutoObservable, runInAction } from 'mobx';

// eslint-disable-next-line import/no-cycle
import carsStore from '@stores/carsStore';
import commonsStore from '@stores/commonsStore';
import ReactGA from 'react-ga4';

export const FUEL_TYPES = {
  PETROL: 'petrol',
  DIESEL: 'diesel',
};

export const SAVING_PERIODS = {
  ANNUAL: 'annual',
  MONTHLY: 'monthly',
};

export const SAVING_TYPES = {
  STANDARD: 'electricity_cost',
  IONITY: 'bmw_charging',
  SMART: 'smart_tariff',
};

const defaultBevForm = {
  fuel_type: FUEL_TYPES.PETROL,
  saving_type: SAVING_TYPES.STANDARD,
  period: SAVING_PERIODS.ANNUAL,
  annual_distance_coverage: null,
  monthly_distance_coverage: null,
  fuel_economy: null,
  diesel_cost: null,
  petrol_cost: null,
  electricity_cost: null,
  bmw_charging: null,
  smart_tariff: null,
};

const defaultBevData = {
  chargingCost: 0,
  fuelCost: 0,
  saved: 0,
};

const defaultPhevForm = {
  fuelType: FUEL_TYPES.PETROL,
  taxSaving: false,
};

const defaultPhevData = {
  monthlySavings: 0,
  firstYearMonthlySavings: 0,
  threeYearsMonthlySavings: 0,
};

const formatLabel = (code, value) => {
  switch (code) {
    case 'annual_distance_coverage':
    case 'monthly_distance_coverage':
      return `${value} ${commonsStore.distanceUnitsShort}`;
    case 'diesel_cost':
    case 'petrol_cost':
      return `${commonsStore.asCurrency(value)}`;
    case 'fuel_economy':
      return `${value} ${commonsStore.fuelEconomyUnits}`;
    case 'electricity_cost':
    case 'bmw_charging':
    case 'smart_tariff':
      return `${value}${commonsStore.currencyHundredthSubdivisionSymbol}`;
    default:
      return value;
  }
};

export class CalculatorStore {
  constructor() {
    makeAutoObservable(this);
  }

  journeyPlans = [];

  calculationParams = {
    annual_distance_coverage: { values: [] },
    monthly_distance_coverage: { values: [] },
    petrol_cost: { values: [] },
    diesel_cost: { values: [] },
    fuel_economy: { values: [] },
    electricity_cost: { values: [] },
    bmw_charging: { values: [] },
    smart_tariff: { values: [] },
  };

  activePlan = null;

  bevForm = defaultBevForm;

  bevData = defaultBevData;

  phevForm = defaultPhevForm;

  phevData = defaultPhevData;

  setJourneyPlans = (plans) => {
    this.journeyPlans = plans;
  };

  setCalculationParams = (params) => {
    params.forEach((param) => {
      this.calculationParams[param.code] = {
        ...param,
        values: param.values.map((value) => ({
          label: formatLabel(param.code, value),
          value,
        })),
      };
    });
  };

  selectPlan = (kind) => {
    this.activePlan = kind;

    this.getPhevSavings();
  };

  setBevFormValue = (key, value) => {
    this.bevForm[key] = value;

    this.getBevSavings();
  };

  setPhevFormValue = (key, value) => {
    this.phevForm[key] = value;

    this.getPhevSavings();
  };

  getBevSavings = async () => {
    try {
      if (carsStore.activeCar && Object.values(this.bevForm).includes(null)) {
        Object.entries(this.bevForm).forEach(([key, value]) => {
          if (!value && this.calculationParams[key]) {
            runInAction(() => {
              this.bevForm[key] = this.calculationParams[key].default;
            });
          }
        });
      }

      const {
        petrol_cost,
        diesel_cost,
        annual_distance_coverage,
        monthly_distance_coverage,
        saving_type,
        ...formData
      } = this.bevForm;

      const electricity_cost = this.bevForm[saving_type];
      const payload = {
        ...formData,
        fuel_cost:
          formData.fuel_type === FUEL_TYPES.PETROL ? petrol_cost : diesel_cost,
        electricity_cost,
      };

      payload.distance_coverage =
        formData.period === 'annual'
          ? annual_distance_coverage
          : monthly_distance_coverage;

      if (carsStore.activeCar && !Object.values(payload).includes(null)) {
        const { data } = await API.post(
          APIRoutes.BEV_SAVINGS(carsStore.activeCar.id),
          {
            ...payload,
          },
        );

        runInAction(() => {
          this.captureElectricSavingsCalculatorEvent(this.bevForm);
          this.bevData = data;
        });
      } else {
        runInAction(() => {
          this.bevData = defaultBevData;
        });
      }
    } catch (error) {
      console.warn(error);
    }
  };

  getPhevSavings = async () => {
    try {
      if (carsStore.activeCar && !this.activePlan) {
        this.activePlan = this.journeyPlans.find((el) => el.default)?.id;
      }

      if (carsStore.activeCar && this.activePlan) {
        const { data } = await API.post(
          APIRoutes.PHEV_SAVINGS(carsStore.activeCar.id),
          {
            journey_plan_id: this.activePlan,
            compare_to: this.phevForm.fuelType,
            include_ved: this.phevForm.taxSaving,
          },
        );

        runInAction(() => {
          this.capturePhevSavingsCalculatorEvent(this.phevForm);
          this.phevData = data;
        });
      }
    } catch (error) {
      console.warn(error);
    }
  };

  captureElectricSavingsCalculatorEvent = (form) => {
    const savingsCalutatorEvent = {
      category: 'Electric Savings Calculator',
      action: 'New Savings Calculation',
      saving_type: form.saving_type,
      fuel_type: form.fuel_type,
      period: form.period,
    };

    ReactGA.event(savingsCalutatorEvent);
  };

  capturePhevSavingsCalculatorEvent = (form) => {
    const savingsCalutatorEvent = {
      category: 'PHEV Savings Calculator',
      action: 'New Savings Calculation',
      fuel_type: form.fuelType,
      include_ved_savings: form.taxSaving,
    };

    ReactGA.event(savingsCalutatorEvent);
  };

  resetCalculator = () => {
    this.activePlan = null;
    this.bevForm = defaultBevForm;
    this.bevData = defaultBevData;
    this.phevForm = defaultPhevForm;
    this.phevData = defaultPhevData;
  };
}

export default new CalculatorStore();
