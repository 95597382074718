import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';

import { breakpoint } from '@styles/breakpoints';
import {
  HEADING,
  Button as DefaultButton,
  RESET_BUTTON,
} from '@styles/globalStyles';

export const SliderWrapper = styled.div`
  margin: -20px auto 48px;

  max-width: ${({ theme }) => theme.banners.maxWidth}px;

  & > .swiper-container {
    padding-bottom: 30px;

    .swiper-slide {
      width: calc(100% - 20px);
    }

    .swiper-pagination {
      display: ${({ showPagination }) => (showPagination ? 'block' : 'none')};
    }

    .swiper-pagination-bullet {
      margin: 0;
      border-radius: 0;
      height: 5px;
      width: 30px;
      background-color: #f0f0f0;
      opacity: 1;

      @media ${breakpoint.md} {
        &:hover {
          background-color: #d4d4d4;
        }
      }
    }

    .swiper-pagination-bullet-active {
      background-color: #8e8e8e;

      &:hover {
        background-color: #8e8e8e;
      }
    }

    @media ${breakpoint.tabletPortrait} {
      padding-bottom: 40px;
      margin: 64px auto 48px;

      .swiper-slide {
        width: 505px;
      }
    }

    @media ${breakpoint.md} {
      .swiper-slide {
        width: 584px;
      }
    }
  }
`;

export const StyledSwiperWrapper = styled.div`
  width: 100%;
  position: relative;

  .swiper-slide {
    /* slide background to prevent overlap while fade effect */
    background: #ffffff;
  }
`;

export const SwiperNavigation = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 35%;
  z-index: 10;
  display: flex;
  justify-content: space-between;

  @media ${breakpoint.md} {
    top: 40%;
  }
`;

export const NavArrow = styled.button`
  ${RESET_BUTTON()}
  margin: 0 10px;
  padding: 10px;
`;

export const Text = styled.div`
  width: fit-content;
  margin: 20px 0 10px 20px;
  padding: 0;
  position: relative;
  z-index: 1;

  cursor: pointer;

  @media ${breakpoint.tabletPortrait} {
    margin: 40px 0 0 40px;
    padding: 0;
  }
`;

export const Title = styled.h3`
  ${({ theme }) => HEADING(theme)}
  font-size: ${({ theme }) => theme.fontSizes[5]}px;
  margin-bottom: 8px;

  @media ${breakpoint.tabletPortrait} {
    font-size: ${({ theme }) => theme.fontSizes[6]}px;
  }
`;

export const Description = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: #666666;
`;

export const Model = styled.div`
  background: #ffffff;
`;

export const Background = styled.div`
  background: #f8f8f8;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  bottom: 30%;

  @media ${breakpoint.tabletPortrait} {
    bottom: unset;
    height: 280px;
  }

  @media ${breakpoint.md} {
    height: 275px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.3s;

    ${({ gradientStart, gradientEnd }) => `
    background: linear-gradient(180deg, ${gradientStart} 0%, ${gradientEnd} 100%)
    `};
  }
`;

export const Car = styled.div`
  position: relative;
  margin: 0 auto;
  width: fit-content;
  min-height: 130px;
  cursor: pointer;
  transition: transform 0.3s;

  @media ${breakpoint.tabletPortrait} {
    min-height: unset;
  }
`;

const CAR_SIZES = css`
  display: inherit;
  margin: 10px auto 0;

  width: auto;
  max-width: calc(100% - 40px);
  min-height: 180px;
  max-height: 220px;

  object-fit: contain;
  object-position: bottom;

  @media ${breakpoint.tabletPortrait} {
    height: 200px;
  }

  @media ${breakpoint.md} {
    height: 211px;
  }
`;

export const CarBase = styled.img`
  ${CAR_SIZES};
`;

export const CarCover = styled.img`
  position: absolute;
  top: -10px;
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;

  ${CAR_SIZES};
`;

export const Button = styled(DefaultButton)`
  position: relative;

  margin: 24px auto 0;
  opacity: 1;
  transition: opacity 0.3s;

  @media ${breakpoint.md} {
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
  }

  & > span {
    transition: opacity 0.3s;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);

    &:first-of-type {
      opacity: ${({ isSelected }) => (isSelected ? 0 : 1)};
    }

    &:last-of-type {
      opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
    }
  }

  img {
    width: 24px;
    padding: 0 16px;
    box-sizing: content-box;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.7;
  }
`;

export const Item = styled.div`
  position: relative;

  ${({ isFocus }) =>
    isFocus &&
    `
  ${CarCover} {
    opacity: 1;
  }

  ${Car} {
    transform: scale(1.035);

    @media ${breakpoint.tabletPortrait} {
      transform: scale(1.05);
    }
  }

  ${Background} {
    &:after {
      opacity: 1;
    }
  }

  ${Title} {
    color: #ffffff;
  }

  ${Description} {
    color: #ffffff;
  }

  ${Button} {
    opacity: 1;
  }
  `}
`;

export const ButtonIcon = styled.img``;

export const SelectedButtonText = styled.span``;

export const DefaultButtonText = styled.span``;
