import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

import { Annotation, Container } from '@styles/globalStyles';
import { PAGES } from '@routes';
import BenefitsSection, {
  IntroductionSection,
} from '@components/BenefitsSection';
import Banner, { BANNER_VARIANTS } from '@components/Banner';

const Charging = ({
  pagesStore: {
    charging: { data },
    getPage,
  },
  retailerStore: { dealerNumber, isDealerSet },
  commonsStore: { isIrelandSite },
}) => {
  useEffect(() => {
    isDealerSet && getPage({ page: PAGES.charging, dealerNumber });
  }, [isDealerSet]);

  const sectionsData = data?.pageSections || [];
  const banners = data?.banners || [];

  return (
    <>
      <Helmet>
        <title>Charging | BMW Electrified</title>
        {isIrelandSite && <meta name="robots" content="noindex" />}
      </Helmet>
      <Container>
        <IntroductionSection content={data.description} title={data.headline} />
      </Container>
      {sectionsData.map((section, index) => (
        <BenefitsSection key={`lp-section_${index}`} section={section} />
      ))}
      {banners.map((banner, index) => (
        <Banner
          key={`banner-${index}`}
          {...banner}
          variant={BANNER_VARIANTS.CHARGING}
        />
      ))}
      {data.annotation && (
        <Container>
          <Annotation dangerouslySetInnerHTML={{ __html: data.annotation }} />
        </Container>
      )}
    </>
  );
};

Charging.propTypes = {
  pagesStore: PropTypes.shape({
    charging: PropTypes.shape({
      data: PropTypes.object,
      isLoading: PropTypes.bool,
    }),
    getPage: PropTypes.func,
  }).isRequired,
  pageStore: PropTypes.object.isRequired,
};

export default inject(
  'commonsStore',
  'pagesStore',
  'retailerStore',
)(observer(Charging));
