import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import { Container } from '@styles/globalStyles';
import { Flex } from 'rebass';
import {
  ColumnLinks,
  Copy,
  Wrapper,
  LinkBoxHeader,
  Link,
  SocialLinks,
  SocialLink,
  SocialRow,
  Header,
} from '@components/Footer/style';

import icons from '@components/Footer/icons';

const Footer = ({
  commonsStore: {
    footer: { contact, legal, socialMedia },
    isIrelandSite,
  },
}) => (
  <Wrapper>
    <Container>
      <SocialRow>
        <Header>Social Media</Header>
        <SocialLinks>
          {socialMedia.map(({ label, link, target }, index) => (
            <SocialLink
              key={`social-link_${index}`}
              href={link}
              target={target}
              aria-label={label}
              rel="noopener"
            >
              {icons[label]}
            </SocialLink>
          ))}
        </SocialLinks>
      </SocialRow>
      <Flex justifyContent="flex-start" flexWrap="wrap">
        <ColumnLinks>
          <LinkBoxHeader>Contact</LinkBoxHeader>
          {contact.map(({ label, link, target }, index) => (
            <Link key={`footer-contact_${index}`} href={link} target={target}>
              {label}
            </Link>
          ))}
        </ColumnLinks>

        <ColumnLinks>
          <LinkBoxHeader>Legal</LinkBoxHeader>
          {legal.map(({ label, link, target }, index) => (
            <Link key={`footer-legal_${index}`} href={link} target={target}>
              {label}
            </Link>
          ))}
        </ColumnLinks>
      </Flex>
    </Container>

    <Copy>
      <Container>
        © BMW {isIrelandSite ? 'IE' : 'UK'} {new Date().getFullYear()}
      </Container>
    </Copy>
  </Wrapper>
);

Footer.propTypes = {
  commonsStore: PropTypes.shape({
    footer: PropTypes.shape({
      contact: PropTypes.array,
      legal: PropTypes.array,
      socialMedia: PropTypes.array,
    }),
    isIrelandSite: PropTypes.bool,
  }).isRequired,
};

export default inject('commonsStore')(observer(Footer));
