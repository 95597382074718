import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import CarItem from '@containers/Home/components/CarsSlider/components/CarItem';
import { SliderWrapper } from '@containers/Home/components/CarsSlider/components/style';

const getSlideIds = (carsList, activeCarId) => {
  let mainSlideId = null;
  let innerSlideId = null;

  if (activeCarId) {
    carsList.forEach(({ vehicles }, index) => {
      const innerId = vehicles.findIndex(({ id }) => id === activeCarId);
      if (innerId > -1) {
        mainSlideId = index;
        innerSlideId = innerId;
      }
    });
  }

  return {
    mainSlideId,
    innerSlideId,
  };
};

const swiperSettings = {
  centeredSlides: true,
  slidesPerView: 'auto',
  spaceBetween: 16,
  threshold: 10,
  pagination: {
    clickable: true,
  },
};

const CarsSlider = ({
  carsStore: {
    carKinds,
    activeKind,
    activeGroup,
    setActiveCar,
    activeCar,
    activeSwiperIndex,
  },
}) => {
  const ref = useRef(null);
  const [mainActiveIndex, setMainActiveIndex] = useState(0);
  const [innerIndex, setInnerIndex] = useState(null);

  let carsList = [];
  if (activeGroup === 'all') {
    carsList = carKinds[activeKind].vehicles.reduce(
      (acc, { name, series }) => [
        ...acc,
        ...series.map((item) => ({ group: name, ...item })),
      ],
      [],
    );
  } else {
    const activeGroupObject = carKinds[activeKind].vehicles.find(
      ({ name }) => name === activeGroup,
    );

    carsList = activeGroupObject?.series.reduce(
      (acc, series) => [
        ...acc,
        ...series.vehicles.map((vehicle) => ({
          ...series,
          group: activeGroupObject.name,
          vehicles: [vehicle],
        })),
      ],
      [],
    );
  }

  useEffect(() => {
    if (ref.current?.swiper) {
      ref.current?.swiper.slideTo(0, 0, false);
    }
  }, [activeGroup, activeKind]);

  useEffect(() => {
    if (ref.current?.swiper) {
      ref.current?.swiper.on('slideChange', ({ activeIndex }) => {
        setMainActiveIndex(activeIndex);
      });

      /* Scroll to selected vehicle */
      if (activeGroup === 'all') {
        const { mainSlideId, innerSlideId } = getSlideIds(
          carsList,
          activeCar?.id,
        );

        if (
          typeof mainSlideId === 'number' &&
          typeof innerSlideId === 'number'
        ) {
          ref.current?.swiper.slideTo(mainSlideId, 0, false);
          setInnerIndex(innerSlideId);
          return;
        }
      }

      /* Default scroll to start */
      ref.current?.swiper.slideTo(activeSwiperIndex, 0, false);
    }
  }, [ref.current]);

  const handleSetActiveCar = (data) => {
    setActiveCar({
      ...data,
      mainSlideId: ref.current?.swiper.activeIndex,
    });
  };

  return (
    <SliderWrapper showPagination={carsList.length > 1}>
      <Swiper ref={ref} {...swiperSettings}>
        {carsList.map((series, index) => (
          <SwiperSlide key={`car-item_${index}`}>
            <CarItem
              {...series}
              setActiveCar={handleSetActiveCar}
              activeCarId={activeCar?.id}
              innerIndex={innerIndex}
              activeSlideId={ref.current?.swiper.activeIndex}
              isActive={index === mainActiveIndex}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </SliderWrapper>
  );
};

CarsSlider.propTypes = {
  carsStore: PropTypes.shape({
    carKinds: PropTypes.object,
    activeCar: PropTypes.object,
    activeKind: PropTypes.string,
    activeGroup: PropTypes.string,
    setActiveCar: PropTypes.func,
  }).isRequired,
};

export default inject('carsStore')(observer(CarsSlider));
