import React from 'react';
import { StickyWrap } from '@components/Sticky/style';

class Sticky extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sticky: true,
    };
    this.body = React.createRef();
    this.listen();
  }

  listen() {
    window.addEventListener('scroll', () => {
      if (window.scrollY < 500) {
        return;
      }
      const { bottom } = this.body.current.getBoundingClientRect();
      const sticky = window.innerHeight - Math.floor(bottom) <= 1;
      this.setState({
        sticky,
      });
    });
  }

  render() {
    const { state, props, body } = this;
    const { children } = props;
    const { sticky } = state;
    return (
      <StickyWrap ref={body} data-sticky={sticky}>
        {children}
      </StickyWrap>
    );
  }
}

export default Sticky;
