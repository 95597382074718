import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Route from '@components/Route';
import routes, { PAGES } from '@routes';
import Electrics from '@containers/Electrics';
import Phev from '@containers/Phev';
import Home from '@containers/Home';
import Charging from '@containers/Charging';
import DataPage from '@containers/DataPage';
import StaticPage from '@containers/DataPage/CookiePage';

const NationalSite = () => (
  <Switch>
    <Route exact path={routes.phev} page={PAGES.phev}>
      <Phev />
    </Route>
    <Route exact path={routes.electric} page={PAGES.electric}>
      <Electrics />
    </Route>
    <Route exact path={routes.charging} page={PAGES.charging}>
      <Charging />
    </Route>
    <Route exact path={routes.chargingFaqs}>
      <DataPage page={PAGES.chargingFaqs} />
    </Route>
    <Route exact path={routes.calculationsExplained}>
      <DataPage page={PAGES.calculationsExplained} />
    </Route>
    <Route exact path={routes.electricCalculationsExplained}>
      <DataPage page={PAGES.electricCalculationsExplained} />
    </Route>
    <Route exact path={routes.cookiePolicy}>
      <StaticPage />
    </Route>
    <Route exact path={`${routes.homepage}:model?`} page={PAGES.homepage}>
      <Home />
    </Route>
    <Redirect from="*" to={routes.homepage} push />
  </Switch>
);

export default NationalSite;
