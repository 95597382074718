/* eslint-disable no-console */
import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes } from '@app/api';
// eslint-disable-next-line import/no-cycle
import calculatorStore from '@stores/calculatorStore';

export class PagesStore {
  constructor() {
    makeAutoObservable(this);
  }

  homepage = {
    isLoading: false,
    isLoaded: false,
    error: null,
    data: {
      description: '',
      headline: '',
      pageSections: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      banners: [],
    },
  };

  phev = {
    isLoading: false,
    isLoaded: false,
    error: null,
    data: {},
  };

  electric = {
    isLoading: false,
    isLoaded: false,
    error: null,
    data: {},
  };

  charging = {
    isLoading: false,
    isLoaded: false,
    error: null,
    data: {},
  };

  dataPage = {
    isLoading: false,
    isLoaded: false,
    error: null,
    data: {},
  };

  getPage = async ({ page, key, dealerNumber }) => {
    const dataKey = key || page;
    try {
      this[dataKey].isLoading = true;
      const {
        data: { page: pageData, calculationParams, journeyPlans },
      } = await API(APIRoutes.PAGES(page, dealerNumber));

      runInAction(() => {
        this[dataKey].data = pageData;
        this[dataKey].isLoaded = true;
        if (journeyPlans) {
          calculatorStore.setJourneyPlans(journeyPlans);
        }
        if (calculationParams) {
          calculatorStore.setCalculationParams(calculationParams);
        }
      });
    } catch (error) {
      runInAction(() => {
        this[dataKey].error = error;
      });

      console.warn(error);
    } finally {
      runInAction(() => {
        this[dataKey].isLoading = false;
      });
    }
  };

  pagePropertyValue = (page, slug) => {
    const pageProperties = this[page].data.pageProperties || [];

    return pageProperties.find((p) => p.slug === slug)?.value;
  };

  getSection = (page, slug) => {
    const sections = this[page].data.pageSections || [];
    const fallback = {
      title: '',
      description: '',
      annotation: '',
      additionalDescription: '',
    };
    return sections.find((p) => p.slug === slug) || fallback;
  };
}

export default new PagesStore();
