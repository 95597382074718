import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const ContentWrapper = styled.div`
  h1 {
    margin-top: 70px;
    margin-bottom: 15px;
    font-size: 35px;
    line-height: 1em;
    text-transform: uppercase;

    @media ${breakpoint.md} {
      margin-top: 80px;
      margin-bottom: 30px;
      font-size: 50px;
    }
  }
  h2 {
    margin-top: 45px;
    margin-bottom: 15px;
    font-size: 20px;

    @media ${breakpoint.md} {
      font-size: 25px;
    }
  }
  h3 {
    margin: 10px 0;
    font-size: 24px;
  }
  p {
    margin-bottom: 24px;
    font-size: 15px;
  }
  strong {
    font-weight: 600;
  }
  table {
    width: 100%;
    max-width: 900px;
    margin-bottom: 35px;
    @media ${breakpoint.md} {
      margin-bottom: 65px;
    }
  }
  tr {
    border-bottom: 1px solid #bbb;
  }
  th,
  td {
    width: 50%;
    padding: 25px 13px 24px;
    font-size: 16px;
    line-height: 1.33em;
    text-align: right;
    &:first-of-type {
      text-align: left;
    }
    &:nth-of-type(3) {
      display: none;
    }
    @media ${breakpoint.md} {
      width: 25%;
      text-align: left;
      &:first-of-type {
        width: 47.55%;
      }
      &:nth-of-type(2) {
        width: 29.56%;
      }
      &:nth-of-type(3) {
        width: 22.89%;
        display: table-cell;
      }
    }
  }
  th {
    padding-top: 15px;
    padding-bottom: 15px;
    font-family: ${(props) => props.theme.fonts.bold};
    text-align: left;
  }
  td {
    &.bold {
      font-family: ${(props) => props.theme.fonts.bold};
    }
    &.hide-on-mobile {
      display: none;
      @media ${breakpoint.md} {
        display: table-cell;
      }
    }
  }
  .show-on-mobile {
    display: block;
    @media ${breakpoint.md} {
      display: none;
    }
  }
`;
