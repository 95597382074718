import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { Container, HEADING } from '@styles/globalStyles';
import { breakpoint } from '@styles/breakpoints';
import Section from '@components/Section';

export const IntroductionSection = styled(Section)`
  max-width: 784px;
  margin: 0 auto 60px;

  @media ${breakpoint.tabletPortrait} {
    margin: 0 auto 50px;
  }

  @media ${breakpoint.md} {
    margin: 60px auto 135px;
  }
`;

export const Header = styled.div`
  ${({ theme }) => HEADING(theme)};
  font-size: ${({ theme }) => theme.fontSizes[3]}px;
  margin: 30px 0 8px;

  @media ${breakpoint.tabletPortrait} {
    font-size: ${({ theme }) => theme.fontSizes[4]}px;
    line-height: ${({ theme }) => theme.lineHeights[4]}px;
    margin: 30px 0 10px;
  }

  @media ${breakpoint.md} {
    margin: 0 0 8px;
  }
`;

export const SubHeader = styled.div`
  ${({ theme }) => HEADING(theme)};

  margin: -10px 0 10px;
  font-size: 16px;

  @media ${breakpoint.md} {
    font-size: 20px;
    margin: 0 0 15px 0;
  }
`;

export const LayoutWrapper = styled.div`
  order: 2;
  padding: 0;

  @media ${breakpoint.md} {
    order: 1;
    padding: 0 30px;
  }
`;

export const SectionText = styled.div`
  + a {
    margin-top: 20px;

    @media ${breakpoint.md} {
      margin-top: 40px;
    }
  }
`;

export const SectionContent = styled.div`
  order: 2;
  padding: 0 20px;

  p {
    font-size: 16px;

    & + p {
      margin-top: 15px;
    }
  }

  @media ${breakpoint.tabletPortrait} {
    width: 100%;
    max-width: none;
    padding: 0;
  }

  @media ${breakpoint.md} {
    order: 1;
    max-width: 686px;
    padding: 0 10%;
  }

  @media ${breakpoint.xl} {
    padding: 0 100px;
  }
`;

export const SectionImage = styled.div`
  width: 100%;
  order: 1;

  img {
    width: 100%;
    vertical-align: bottom;
  }

  @media ${breakpoint.md} {
    order: 2;
    width: auto;
  }

  ${(props) =>
    props.mobileOnly &&
    css`
      order: 1;
      @media ${breakpoint.md} {
        display: none;
      }
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  max-width: ${({ theme }) => theme.banners.maxWidth}px;
  margin: 0 auto 60px;
  flex-wrap: wrap;
  padding: 0;

  & + ${Container} {
    margin-top: 100px;
  }

  &:nth-of-type(2n) {
    flex-direction: column;

    ${SectionImage} {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media ${breakpoint.tabletPortrait} {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto 50px;
    padding: 0 45px;

    &:nth-of-type(2n) {
      flex-direction: row-reverse;
    }

    & + ${Container} {
      margin-top: 100px;
    }
  }

  @media ${breakpoint.md} {
    padding: 0;
    margin: 0 auto 80px;
    justify-content: flex-end;
    flex-wrap: nowrap;
    ${(props) =>
      props.image
        ? css`
            background-image: url(${props.image[0]});
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            padding: 126px 0;

            ${SectionImage} {
              width: 100%;
            }
          `
        : null}
  }
`;
