import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@components/Tooltip';
import { InfoIcon } from '@styles/globalStyles';
import styled from '@emotion/styled/';

const Text = styled.span`
  margin-right: 16px;
`;

const TooltipText = styled.span`
  font-family: ${({ theme }) => theme.fonts.light};
  font-size: 13px;

  strong {
    font-family: ${({ theme }) => theme.fonts.bold};
    display: block;
  }
`;

const InfoLabel = ({ label, tooltipValue }) => {
  const [show, setShow] = useState(false);

  return (
    <span
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <Text>{label}</Text>
      <Tooltip
        tooltipBody={
          <TooltipText>
            <strong>In comparison to:</strong>
            {tooltipValue}
          </TooltipText>
        }
        show={show}
        offset={[-50, 12]}
      >
        <InfoIcon />
      </Tooltip>
    </span>
  );
};

InfoLabel.propTypes = {
  label: PropTypes.string.isRequired,
  tooltipValue: PropTypes.string.isRequired,
};

export default InfoLabel;
