/* eslint-disable max-len */
import React, { useState, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { func, number, shape, string } from 'prop-types';

import {
  FormContainer,
  FieldContainer,
  Box,
  BoxRow,
  MiddleColumn,
  ReverseButton,
} from '@containers/Home/components/ChargingPointsForm/style';
import Tabs from '@components/Tabs';
import { TextField, Stepper } from '@components/Form';
import { Button } from '@styles/globalStyles';
import useMatchBreakpoint from '@root/hooks/useMatchBreakpoint';
import { breakpoint } from '@styles/breakpoints';

const PostcodeIndicator = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.769"
    height="20"
    viewBox="0 0 21.769 20"
  >
    <path
      id="prefix__Path_300"
      d="M18.8 27.73h-.111q-.037 0-.037-.068a.774.774 0 0 1-.594-.239 1.219 1.219 0 0 1-.3-.512l-.817-8.123-8.841-.819a1.416 1.416 0 0 1-.632-.273.6.6 0 0 1-.26-.478v-.2a.792.792 0 0 1 .149-.478 1.1 1.1 0 0 1 .446-.341l19.242-7.991a1.278 1.278 0 0 1 .371-.341.976.976 0 0 1 .52-.137 1.056 1.056 0 0 1 .743.27.819.819 0 0 1 .3.614.952.952 0 0 1-.149.512.68.68 0 0 1-.446.307l-8.621 17.751a1.54 1.54 0 0 1-.409.375 1 1 0 0 1-.554.171z"
      data-name="Path 300"
      transform="translate(-7.207 -7.73)"
    />
  </svg>
);

const ChargingPointsForm = ({
  mapDataStore: {
    resetForm,
    startZip,
    endZip,
    minRadiusValue,
    radiusValue,
    maxRadiusValue,
    stepCount,
    setRadius,
    setValue,
    filterMarkers,
    swapZipCodes,
    showMapOnMobile,
    setMapOnMobile,
  },
  commonsStore: { distanceUnitsLong },
}) => {
  const formStartRef = useRef(null);
  const [selectedTab, setTab] = useState(0);

  const isMobile = !useMatchBreakpoint(breakpoint.tabletPortrait);

  const switchTab = (id) => {
    setTab(id);
    resetForm();
  };

  const handleInputChange = ({ target }) => {
    const { value } = target;
    const key = target.name;

    setValue(key, value);
  };

  const seeResults = async () => {
    setMapOnMobile(isMobile);
    await filterMarkers();
  };

  return (
    <>
      <FormContainer ref={formStartRef}>
        <Tabs
          id="calcType"
          onChange={switchTab}
          tabs={[{ text: 'Select by radius' }, { text: 'Select by journey' }]}
        />
        {!showMapOnMobile && (
          <>
            <BoxRow>
              <Box>
                <TextField
                  id="startZip"
                  name="startZip"
                  label={
                    selectedTab === 0
                      ? 'Please enter postcode'
                      : 'Please enter a from postcode'
                  }
                  placeholder="Enter postcode"
                  value={startZip}
                  onChange={handleInputChange}
                  inputIndicator={PostcodeIndicator}
                />
              </Box>
              {selectedTab === 0 ? (
                <Box>
                  <Stepper
                    id="distance-radius"
                    label={`Please select a radius in ${distanceUnitsLong}`}
                    min={minRadiusValue}
                    max={maxRadiusValue}
                    steps={stepCount}
                    value={radiusValue}
                    onChange={setRadius}
                    formatLabel={(value) => `${value} ${distanceUnitsLong}`}
                  />
                </Box>
              ) : (
                <>
                  <MiddleColumn>
                    <ReverseButton type="button" onClick={swapZipCodes}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        viewBox="0 0 25 13.634"
                      >
                        <path
                          d="M7.387,5.682H0L7.387,0V3.409H25V5.682Z"
                          transform="translate(0 0)"
                        />
                        <path
                          d="M7.387,5.681H0L7.387,0V3.409H25V5.681Z"
                          transform="translate(25 13.634) rotate(180)"
                        />
                      </svg>
                    </ReverseButton>
                  </MiddleColumn>
                  <Box>
                    <TextField
                      id="endZip"
                      name="endZip"
                      label="Please enter a to postcode"
                      placeholder="Enter postcode"
                      value={endZip}
                      onChange={handleInputChange}
                      inputIndicator={PostcodeIndicator}
                    />
                  </Box>
                </>
              )}
            </BoxRow>
            <FieldContainer noPadding center>
              <Button variant="primary" onClick={seeResults}>
                See results
              </Button>
            </FieldContainer>
          </>
        )}
      </FormContainer>
    </>
  );
};

ChargingPointsForm.propTypes = {
  mapDataStore: shape({
    resetForm: func,
    swapZipCodes: func,
    setRadius: func,
    setValue: func,
    filterMarkers: func,
    startZip: string,
    endZip: string,
    radiusValue: number,
  }).isRequired,
  commonsStore: shape({
    distanceUnitsLong: string,
  }).isRequired,
};

export default inject(
  'mapDataStore',
  'commonsStore',
)(observer(ChargingPointsForm));
