export const size = {
  xs: 320,
  sm: 500,
  md: 769,
  lg: 1024,
  xl: 1200,
  xxl: 1600,
  tabletPortrait: 700,
};

export const breakpoint = {
  xs: `(min-width: ${size.xs}px)`,
  sm: `(min-width: ${size.sm}px)`,
  md: `(min-width: ${size.md}px)`,
  lg: `(min-width: ${size.lg}px)`,
  xl: `(min-width: ${size.xl}px)`,
  xxl: `(min-width: ${size.xxl}px)`,
  tabletPortrait: `(min-width: ${size.tabletPortrait}px)`,
  tabletOnly: `(min-width: ${size.md - 1}px) and (max-width: ${size.lg - 1}px)`,
  tabletAndBeyond: `(min-width: ${size.md - 1}px)`,
  mobileOnly: `(max-width: ${size.md - 1}px)`,
  smAndBeyond: `(min-width: ${size.sm - 1}px)`,
};
