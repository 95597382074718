import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '@components/Form/style';
import * as Styled from '@components/Form/Radio/style';

const Radio = ({ label, active, value, ...inputProps }) => (
  <Styled.Radio>
    <input
      type="radio"
      checked={active === value}
      value={value}
      {...inputProps}
    />
    <Label>{label}</Label>
    <Styled.RadioCheckMark />
  </Styled.Radio>
);

Radio.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  active: PropTypes.string.isRequired,
};

export default Radio;
