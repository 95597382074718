import styled from '@emotion/styled/macro';
import { breakpoint } from '@styles/breakpoints';
import Section from '@components/Section';
import { FlexRow } from '@styles/globalStyles';

export const IntroductionSection = styled(Section)`
  max-width: 785px;
  margin: 0 auto 60px;

  @media ${breakpoint.sm} {
    margin: 0 auto 60px;
  }

  @media ${breakpoint.lg} {
    margin: 60px auto 105px;
  }

  h2 {
    display: none;
    font-size: 25px;
    margin-bottom: 15px;

    @media ${breakpoint.md} {
      display: block;
      font-size: 35px;
      margin-bottom: 10px;
    }
  }
`;

export const GroupLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  margin: 12px 0 24px;

  @media ${breakpoint.md} {
    margin: 24px 0;
  }
`;

export const SavingsRow = styled(FlexRow)`
  margin: 0 -8px;
  margin-top: 30px;

  @media ${breakpoint.tabletPortrait} {
    margin-top: 24px;
  }
`;

export const MapKey = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

export const MapKeySection = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    height: 38px;
  }
`;
